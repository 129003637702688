import React from "react";
import styled from "@emotion/styled";

const ShakeAnimation = styled.div`
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-2px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-1px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(1px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(0deg);
    }
  }
`;

function OTPInputComponent({ otp, hasError, onChange, shouldAutoFocus }) {
  const Container = hasError ? ShakeAnimation : React.Fragment;

  return (
    <Container>
      <input
        type="text"
        value={otp}
        onChange={(e) => onChange(e.target.value.slice(0, 6))}
        autoComplete="one-time-code"
        maxLength={6}
        inputMode="numeric"
        pattern="[0-9]*"
        style={{
          width: "120px",
            paddingTop: "10px",
          letterSpacing: "5px",
          fontFamily: "PetitCochon",
          color: hasError ? "red" : "white",
          height: "40px",
          border: "0.5px solid white",
          borderRadius: "5px",
          margin: "0 5px",
          textAlign: "center",
          fontSize: "27px",
          outline: "none",
          background: "transparent",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
        }}
        autoFocus={shouldAutoFocus}
      />
    </Container>
  );
}

export default OTPInputComponent;