import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Typography, CircularProgress } from "@mui/material";
import { verifyOtpRequest } from "../OTPRequests/verifyOTP";
import { useCallback } from "react";
import OTPInputComponent from "./OTPInput";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const LoadingWrapper = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

const ShakeAnimation = styled.div`
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-2px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-1px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(1px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(0deg);
    }
  }
`;

export function PhoneConfirmation({ phoneNumber, onNext }) {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { t } = useTranslation();

  const handleValidation = useCallback(async () => {
    setIsLoading(true);

    const { success, comment } = await verifyOtpRequest(phoneNumber, otp);

    setIsLoading(false);
    if (!success) {
      setHasError(true);
      setTimeout(() => {
        setHasError(false);
        setOtp("");
      }, 1000); // Effacer après 1 seconde
    } else {
      const nextStep = comment === "must create account" ? 3 : 4;
      onNext(nextStep);
    }
  }, [phoneNumber, otp]);

  useEffect(() => {
    if (otp.length === 6) {
      handleValidation();
    }
  }, [otp, handleValidation]);

  const Container = hasError ? ShakeAnimation : React.Fragment;

  return (
    <Box>
      <Container>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "PetitCochon",
            color: "white",
            textAlign: "center",
            fontSize: "1.2rem",
            marginBottom: "10px",
          }}
        >
          {t("enterCode")}
        </Typography>

        <OTPInputComponent
          otp={otp}
          hasError={hasError}
          shouldAutoFocus={true}
          onChange={(value) => {
            setOtp(value);
            if (hasError) {
              setHasError(false);
            }
          }}
        />

        {isLoading && (
          <LoadingWrapper>
            <CircularProgress
              style={{
                color: "#ffbd59",
                // visibility: isLoading ? "visible" : "hidden",
              }}
            />
          </LoadingWrapper>
        )}
      </Container>
    </Box>
  );
}
