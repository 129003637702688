import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-toastify';

export const verifyOtpRequest = async (phoneNumber, otpCode) => {
    try {
        // console.log("PHONE NUMBER >>>>> " + phoneNumber);
        // console.log("OTP CODE >>>>> " + otpCode);
        const response = await axiosInstance.post('/api/v1/verify-otp', { phoneNumber, otpCode });
        // console.log("SUCCESS >>>>>> " + response.data);
        
        if (response.data.success === true) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('phoneNumber', phoneNumber);
            localStorage.setItem('userId', response.data.userId);
            
            // Vérifiez si le commentaire est 'must create code' et retournez les données appropriées
            if (response.data.comment === 'must create code') {
                return {
                    success: true,
                    comment: 'must create account'
                };
            } else {
                return {
                    success: true
                };
            }
        } else if (response.data.error) {
            toast.error(response.data.error);
            // console.error(response.data.error);
            return {
                success: false,
                error: response.data.error
            };
        }
    } catch (error) {
        // console.log("ERREUR >>> " + error.response.data.error);
        // console.error("Erreur lors de la vérification de l'OTP :", error);
        if(error.response.data.error === 'Use your personnal code, not the sms one.')
            toast.error(error.response.data.error);
        return {
            success: false,
            error: error.response.data.error
        };
    }
};
