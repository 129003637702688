import axios from 'axios';
import { toast } from 'react-toastify';

// console.log(process.env.REACT_APP_BACKEND_URL);
const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

// Intercepteur de réponse pour gérer le code d'état 429
apiInstance.interceptors.response.use(response => {
  return response; // Si la réponse est ok, retournez-la simplement
}, error => {
  if (error.response && error.response.status === 429) {
    // console.log(error.response.data)
    toast.error(error.response.data);
  }
  return Promise.reject(error); // Si une erreur se produit, renvoyez-la pour être traitée par d'autres fonctions de capture d'erreurs
});

export default apiInstance;
