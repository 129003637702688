import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const FoxContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const FoxImage = styled.img`
  width: 165px; // Taille du renard, à ajuster
  margin-top: 100px;
  height: auto;
`;

const SpeechBubble = styled(animated.div)`
  position: absolute;
  bottom: 70%; // Augmente la distance entre le renard et la bulle
  left: -22%;
  transform: translateX(-50%);
  width: auto;
  min-width:230px;
  z-index: 1;
  zIndex: 1;
  max-width: 300px;
  padding: 10px;
  background: white;
  border-radius: 15px;
  border: 2px solid black;
  font-size: 14px;
  text-align: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const SpeechTail = styled.div`
  position: absolute;
//   top: 100%;
  left: 50%;
  bottom: 66%;
  transform: translateX(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  zIndex: 1;
  background: white;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  z-index: -1; // Place la coche derrière la bulle
`;

const FoxHelper = ({ text, state }) => {
  const animationProps = useSpring({
    opacity: text ? 1 : 0,
    transform: text ? 'scale(1)' : 'scale(0.5)'
  });

  const getImageSrc = () => {
    switch(state) {
      case 1: return '/FoxDesign/1.svg';
      case 2: return '/FoxDesign/2.svg';
      case 3: return '/FoxDesign/3.svg';
      case 4: return '/FoxDesign/4.svg';
      default: return '/FoxDesign/1.svg';
    }
  };

  return (
    <FoxContainer>
      <FoxImage src={getImageSrc()} alt="Fox Helper" />
      {text && (
        <>
          <SpeechBubble style={animationProps}>
            {text}
          </SpeechBubble>
          <SpeechTail />
        </>
      )}
    </FoxContainer>
  );
};

export default FoxHelper;
