import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// import right arrow mui 
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const StartButton = ({ handleNext }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "95%", flexDirection: "row", gap: "20px" }}>
      <Button
        onClick={() => handleNext(0)}
        variant="contained"
        style={{
          width: "100%",
          borderRadius: "13px",
          padding: "16px 20px",
          fontSize: "16px",
          textTransform: "none",
          display: "flex",
          justifyContent: "center",
          border: "3px solid rgba(253, 127, 50, 1)",
        //   boxShadow: "2px 2px 0px 0 rgba(253, 127, 50, 1)",
          backdropFilter: "blur(10px)",
          marginBottom: "15px",
          alignItems: "center",
          backgroundColor: "#ffbd59",
          color: "white",
        }}
      >
        <Typography
          fontFamily={"PetitCochon"}
          fontSize={22}
          color="white"
          letterSpacing={2}
          opacity={0.9}
          fontWeight="600"
          marginBottom={-1}
        >
          {t("tutorial")}
        </Typography>
      </Button>
      <Button
        onClick={() => handleNext(1)}
        variant="contained"
        style={{
          width: "170%",
          borderRadius: "13px",
          padding: "16px 20px",
          fontSize: "16px",
          textTransform: "none",
          display: "flex",
        //   marginTop: "6px",
          justifyContent: "center",
        //   boxShadow: "5px 5px 0px 0 rgba(253, 127, 50, 1)",
          backdropFilter: "blur(10px)",
          marginBottom: "15px",
          alignItems: "center",
          backgroundColor: "#ffbd59",
          color: "white",
        }}
      >
        <Typography
          fontFamily={"PetitCochon"}
          fontSize={25}
          color="white"
          letterSpacing={2}
          opacity={0.9}
          marginLeft="10px"
          fontWeight="600"
          marginBottom={-1.5}
        >
          {t("getStarted")}
        </Typography>
        <ArrowForwardIosIcon style={{ marginLeft: "0px", fontWeight: "bold", fontSize: "25px" }} />
      </Button>
    </Box>
  );
};

export default StartButton;
