import React, { useState } from "react";
import { Box } from "@mui/material";
import AnimatedTextGradient from "./AnimatedTextGradient";
import downArrow from "../assets/arrowDown.gif";
import upArrow from "../assets/UpArrow.gif";
import ArrowUpYellow from "../assets/ArrowUpYellow.gif";
import ArrowDownYellow from "../assets/ArrowDownYellow.gif";
import HappyFox from "../assets/2.svg";
import { Typography } from "@mui/material";
import { useEffect } from "react";

// const bounceAnimation = `
//   @keyframes bounce {
//     0% {
//       transform: translateY(0);
//     }
//     50% {
//       transform: translateY(-10px);
//     }
//     100% {
//       transform: translateY(0);
//     }
//   }
// `;

const AnimatedBox = ({ match, sms }) => {
    // console.log("SMS >>>>> " + sms);
  const [expanded, setExpanded] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (expanded) {
        setTimeout(() => {
            setShowContent(true);
        }
        , 200);
    } else {
        setShowContent(false);
    }
}, [expanded]);

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px 15px", // Réduit le padding pour diminuer l'espace au-dessus du texte
        borderRadius: "20px",
        border: match ? "1px solid rgb(245, 192, 106)" : "1px solid #47000080",
        boxShadow: match ? "1px 1px 0px 1px rgba(237,134,70,1)" : "1px 1px 0px 1px #470000",
        transition: "all 0.5s ease",
        height: expanded ? "200px" : "100px", // Hauteur ajustée pour le mouvement de la flèche
        position: "relative",
        width: "298px", // Largeur fixe
      }}
    >
      {/* <style>{bounceAnimation}</style> */}
      <Box sx={{ position: "absolute", top: "20px" }}>
        <AnimatedTextGradient
          text={!match ? "AUCUN MATCH" : "C'EST UN MATCH"}
          colorone={!match ? "rgba(237,134,70,1)" : "rgba(237,134,70,1)"}
            colortwo={!match ? "#d93636" : "rgb(245, 192, 106)"}
          fontFamily="PetitCochon"
          letterSpacing="0.4rem"
          weight={900}
          size="1.6rem"
        />
      </Box>
      <img
        src={expanded ? (!match ? upArrow : ArrowUpYellow) : (!match ? downArrow : ArrowDownYellow)}
        alt={expanded ? "Up Arrow" : "Down Arrow"}
        onClick={toggleExpand}
        style={{
            cursor: "pointer",
            marginBottom: "-3px",
            // animation: "bounce 1s infinite",
            position: "absolute",
            bottom: "10px",
            opacity: expanded ? 1 : 1,
            width: "50px",
        }}
      />
      {showContent && (
          <>
          <AnimatedTextGradient
          text={!match ? "Ce numéro n'a pas entré le votre." : "Félicitation! "}
            colorone="white"
            colortwo="#fff5d6"
            // letterSpacing="0.4rem"
            weight={900}
            size={!match ? "1rem" : "1.4rem"}
          />
          {match && (
              <Box sx={{ height: "10px" }}></Box>
              )}
          <AnimatedTextGradient
            text={!match ? (!sms ? "Reviens régulièrement pour vérifier." : "Tu seras notifié par SMS") : "Ce numéro à déjà créer un compte"}
            colorone="white"
            colortwo="#fff5d6"
            // letterSpacing="0.4rem"
            weight={900}
            size="0.9rem"
          />
          {!match && (
              <Box sx={{ height: "15px" }}></Box>
              )}
          <AnimatedTextGradient
          text={!match ? (!!sms ? "" : "Ou active l'alerte SMS.") : "et à entré votre numéro !"}
            colorone="white"
            colortwo="#fff5d6"
            // letterSpacing="0.4rem"
            weight={900}
            size="0.9rem"
          />
        </>
      )}
<img
        src={HappyFox}
        alt="Happy Fox"
        style={{
          width: "70px", 
          position: "absolute",
          bottom: "-8.5px", // ajustez cette valeur pour positionner HappyFox
          left: "10px" // centre HappyFox horizontalement
        }}
      />
    </Box>
  );
};

export default AnimatedBox;
