import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Typography, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axiosInstance";
import { useRef } from "react";
import OTPInputComponent from "../PhoneInput/OTPInput";
import { useTranslation } from 'react-i18next';

const StepWrapper = styled.div`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.3s ease-in-out;
`;

function validateEmail(email) {
  var re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    color: "white", // changez ceci à la couleur souhaitée
    fontWeight: "bold",
  },
}));

export function AccountCreation({ onNext }) {
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showRecoveryText, setShowRecoveryText] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  const emailRef = useRef(null);

  const errorLauncher = async (errorMessage) => {
    setHasError(true);
    toast.error(errorMessage);
    setTimeout(() => {
      setHasError(false);
      setConfirmCode("");
      setCode("");
    }, 1000); // Effacer après 1 seconde
    };

  useEffect(() => {
    if (step >= 3) {
      setTimeout(() => {
        setShowRecoveryText(true);
      }, 100);
    } else {
      setShowRecoveryText(false);
    }
  }, [step]);

  const checkCode = async (value) => {
    // here we need to check if code is valid with back end
    // if not, we need to turn hasError to true
    // wait 1 second and turn it to false and clear the input
    const token = localStorage.getItem("token");
    if (value.length !== 6) {
      return;
    }
    // console.log("CHECKING CODE TOKEN : " + token);

    try {
      await axiosInstance.post("/api/v1/passcode-check", {
        token,
        code: value,
      });
    //   console.log(response);
      setStep(2);
    } catch (err) {
        errorLauncher(err.response.data.error);
    }
  };

  useEffect(() => {
    if (step >= 2 && confirmCode.length === 6) {
      if (code === confirmCode) {
        setStep(3);
        emailRef.current.focus();
      } else {
        errorLauncher("The passcodes do not match.");
        setStep(1);
      }
    } else if (step === 3 && email) {
      // onNext(email);
    }
  }, [code, confirmCode, email, step, onNext]);

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  const handleNextClick = async () => {
    // Here, you would send your API request with email and code
    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.post("/api/v1/account-creation", {
        token,
        code,
        email,
      });

      if (response.data.success === true) {
        onNext();
      }
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(
          err.response.data.error || "Une erreur est survenue 😢"
        );
      } else {
        toast.error("Une erreur est survenue 😢");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "170px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <StepWrapper show={true} sx={{ marginTop: "0px" }}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "PetitCochon",
              color: "#ffbd59",
              textAlign: "center",
              fontSize: "1rem",
              marginBottom: "10px",
            }}
          >
            {t('createCode')}
          </Typography>

          <OTPInputComponent
            otp={code}
            hasError={hasError}
            shouldAutoFocus={true}
            onChange={(value) => {
              checkCode(value);
              setCode(value);
            }}
          />

          {/* <OtpInput
            value={code}
            autocomplete="one-time-code"
            name="otp"
            onChange={(value) => {
              setCode(value);
            }}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
            inputType="tel"
            shouldAutoFocus={true}
            inputStyle={{
              width: "20px",
              fontFamily: "PetitCochon",
              color: "white",
              height: "40px",
              border: "1px solid #ddd",
              borderRadius: "5px",
              margin: "0 5px",
              textAlign: "center",
              fontSize: "20px",
              outline: "none",
              background: "transparent",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
            }}
          /> */}
        </StepWrapper>

        <Box sx={{ marginTop: "20px" }}>
          <StepWrapper show={step >= 2}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "PetitCochon",
                color: "#ffbd59",
                textAlign: "center",
                fontSize: "1rem",
                marginBottom: "10px",
              }}
            >
                {t('confirmCode')}
            </Typography>

            <OTPInputComponent
              otp={confirmCode}
              hasError={hasError}
              shouldAutoFocus={step === 2}
              onChange={(value) => {
                setConfirmCode(value);
              }}
            />

            {/* <OtpInput
              value={confirmCode}
              autocomplete="one-time-code"
              name="otp"
              onChange={(value) => {
                setConfirmCode(value);
              }}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              inputType="tel"
              shouldAutoFocus={step === 2}
              inputStyle={{
                width: "20px",
                fontFamily: "PetitCochon",
                color: "white",
                height: "40px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                margin: "0 5px",
                textAlign: "center",
                fontSize: "20px",
                outline: "none",
                background: "transparent",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
              }}
            /> */}
          </StepWrapper>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <StepWrapper show={step >= 3}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "PetitCochon",
                color: "#ffbd59",
                textAlign: "center",
                fontSize: "1rem",
                marginBottom: "10px",
              }}
            >
                {t('enterEmail')}
            </Typography>
            <StyledTextField
              inputRef={emailRef}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder={t('emailPlaceholder')}
              variant="outlined"
              fullWidth
              InputProps={{
                style: {
                  textAlign: "center",
                },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Helvetica",
                fontWeight: "550",
                color: showRecoveryText ? "white" : "transparent",
                textAlign: "center",
                opacity: 1,
                fontSize: "0.7rem",
                marginTop: "5px",
                transition: "color 0.7s ease-in-out", // animation de fondu enchaîné
                visibility: step >= 3 ? "visible" : "hidden", // caché jusqu'à ce que step soit >= 3
              }}
            >
              🔐 {t('recoveryText')}
            </Typography>

            <Typography
              sx={{
                fontSize: "2.0rem",
                textAlign: "center",
                fontFamily: "PetitCochon",
                color: "#ffbd59",
                marginTop: "20px",
                opacity: isValidEmail && step >= 3 ? 1 : 0.5,
                cursor: "pointer",
                // visibility: isValidEmail ? "visible" : "hidden",
              }}
              onClick={isValidEmail ? handleNextClick : () => {toast.error(t('invalidEmail'))}}
            >
              {t('next')}
            </Typography>
          </StepWrapper>
        </Box>
      </Box>
    </Box>
  );
}
