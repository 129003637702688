import React from 'react';
import { Box, Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import Step5 from "../assets/Tutorial/step5.svg";
import AnimatedTextGradient from '../RevealDashboard/AnimatedTextGradient';
import Button from '@mui/material/Button';


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedImg = styled.img`
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const AnimatedTypography = styled(Typography)`
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const AnimatedTextGradientAnim = styled(AnimatedTextGradient)`
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const AnimatedButton = styled(Button)`
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const AnimatedBox = styled(Box)`
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const Step6Tutoriel = () => {
  return (
    <Box sx={{ alignContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', alignSelf: 'center', alignContent: 'center', textAlign: 'center', padding: '0px' }}>
      <Box sx={{ textAlign: 'center' }}>
      <Box sx={{ marginBottom: '20px', borderRadius: '10px',padding: '30px' }}>
            <AnimatedTextGradientAnim text="Informations importantes" colorone="white" colortwo="#fbcf8e" size="1.3rem" weight={900} delay={0}/>
            <Box sx={{ height: '20px' }}/>



            <AnimatedTextGradientAnim text="Ce site vient d'une experience personnelle, on veut aider les gens à se retrouver, pas faire de l'argent." colorone="white" colortwo="#fbcf8e" size="1rem" weight={700} delay={0.3} />
            <Box sx={{ height: '25px' }}/>
            <AnimatedTextGradientAnim text="Actuellement le site est" colorone="white" colortwo="#fbcf8e" size="1rem" weight={900} delay={1.5} />
            <AnimatedTextGradientAnim text="Non rentable" colorone="white" colortwo="#ffe2b8" size="0.9rem" delay={2} />
            <Box sx={{ height: '30px' }}/>
            <AnimatedTextGradientAnim text="Aucun frais cachées" colorone="white" colortwo="#fbcf8e" size="0.8rem" weight={900} delay={2.2} />
            <AnimatedTextGradientAnim text="Nous ne revendrons jamais vos infos" colorone="white" colortwo="#fbcf8e" size="0.8rem" weight={900} delay={2.3} />
            <AnimatedTextGradientAnim text="Nous n'utilisons pas de publicitées" colorone="white" colortwo="#fbcf8e" size="0.8rem" weight={900} delay={2.4} />
            <AnimatedTextGradientAnim text="Vos infos sont protégées" colorone="white" colortwo="#fbcf8e" size="0.8rem" weight={900} delay={2.5} />


            <Box sx={{ padding: '40px'}}>
            <AnimatedBox sx={{ padding: '20px', borderRadius: '10px', backgroundColor: '#fbcf8e10' }} delay={2.6}>
            <AnimatedTextGradientAnim text="On est là pour toi" colorone="white" colortwo="#fbcf8e" size="1rem" weight={900} delay={2.7} />
            <Box sx={{ height: '10px' }}/>
            <AnimatedTextGradientAnim text="Si t'as un souci ou une question, n'hésite pas à nous contacter par mail à contact@missmyex.fr" colorone="white" colortwo="#ffe2b8" size="0.8rem" delay={2.9} />
            <AnimatedTextGradientAnim text="On veut que ton expérience soit top." colorone="white" colortwo="#ffe2b8" size="0.8rem" delay={3} />
            </AnimatedBox>
            <Box sx={{ height: '20px' }}/>
            <AnimatedTextGradientAnim text="Prêt ? Clique sur le bouton ci dessous" colorone="white" colortwo="#ffe2b8" size="0.8rem" delay={3.5} />
            </Box>
            </Box>
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Box sx={{ height: '20px' }} />
      </Box>
    </Box>
  );
};

export default Step6Tutoriel;
