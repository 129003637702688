import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";
import axiosInstance from "../utils/axiosInstance";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentButtons = () => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appleGooglePayAvailable, setAppleGooglePayAvailable] = useState(false);

  const redirectToCheckout = async () => {
    setLoading(true);
    try {
      // Appeler votre API pour créer une session de paiement
      const response = await axiosInstance.post(
        "/api/v1/create-checkout-session",
        {
          amount: 129, // Montant en centimes
          userNumber: localStorage.getItem("phoneNumber"), // Numéro de téléphone de l'utilisateur
        }
      );

      const stripe = await stripePromise;
      const { sessionId } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Erreur lors de la redirection vers Checkout:", error);
        setLoading(false);
      }
    } catch (error) {
      console.error("Erreur lors de la création de la session de Checkout:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "FR",
        currency: "eur",
        total: { label: "Total", amount: 129 }, // Le montant en centimes (1.29€ ici)
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr); // Active le bouton Apple Pay ou Google Pay si disponible
          setAppleGooglePayAvailable(true); // Affiche que Apple Pay ou Google Pay est disponible
        }
      });
    }
  }, [stripe]);

  return (
    <Elements stripe={stripePromise}>
      {/* Affichez le bouton Apple Pay / Google Pay si disponible */}
      {appleGooglePayAvailable && paymentRequest && (
        <div style={{ marginBottom: "20px" }}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}

      {/* Affichez le bouton classique "Contribuer" */}
      <Button
        onClick={redirectToCheckout}
        variant="contained"
        color="primary"
        disabled={loading}
        style={{
          backgroundColor: "rgb(245, 192, 106)",
          boxShadow: "2px 2px 0px 2px rgba(237,134,70,1)",
        }}
      >
        {!loading ? (
          <Typography
            variant="body1"
            component="div"
            style={{ color: "#212121", fontWeight: "bold", fontSize: "1rem" }}
          >
            Contribuer
          </Typography>
        ) : (
          <CircularProgress size={20} style={{ color: "white" }} />
        )}
      </Button>
    </Elements>
  );
};

export default PaymentButtons;