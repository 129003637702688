import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import FoxTuto from "../assets/Tutorial/tutobyfox.svg";
import Fox from "../assets/2.svg";
import Step1 from "../assets/Tutorial/step1.svg";
import Step2 from "../assets/Tutorial/step2.svg";
import Step3 from "../assets/Tutorial/step3.svg";
import Step4 from "../assets/Tutorial/step4.svg";
import AnimatedTextGradient from "../RevealDashboard/AnimatedTextGradient";
import Step5 from "../assets/Tutorial/step5.svg";
import logo from "../assets/logo.svg";
import styled, { keyframes } from "styled-components";
import Step5Tutoriel from "./Step5Tutoriel";
import Step6Tutoriel from "./Step6Tutoriel";

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AppLogo = styled("img")`
  height: 20vmin;
  pointer-events: none;
  animation: ${bounce} 2s infinite;
`;

const AnimatedImg = styled("img")`
  width: 100%;
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeIn} 1s ease forwards;
`;

const AnimatedTypography = styled(Typography)`
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${(props) => props.$delay || 0}s;
`;

const AnimatedTextGradientAnim = styled(AnimatedTextGradient)`
  opacity: 0;
  transform: translateY(20px);
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${(props) => props.$delay || 0}s;
`;

const Tutorial = ({ handleNext }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [showStartButton, setShowStartButton] = useState(false);

  useEffect(() => {
    if (currentStep === 5) {
      setShowStartButton(false);
      const timer = setTimeout(() => {
        setShowStartButton(true);
      }, 5000); // 5 secondes de délai

      return () => clearTimeout(timer);
    } else {
      setShowStartButton(true);
    }
  }, [currentStep]);

  //   useEffect(() => {
  //     const timer = setTimeout(() => setIsLoading(false), 1700);
  //     return () => clearTimeout(timer);
  //   }, []);

  const handleButtonClick = () => {
    if (currentStep < 6) {
      setCurrentStep(currentStep + 1);
    } else {
      handleNext(1); // Remplacez par votre logique pour la fin du tutoriel
    }
  };

  //   if (isLoading) {
  //     return (
  //       <Box
  //         sx={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           height: "100vh",
  //         }}
  //       >
  //         <AppLogo src={logo} alt="Loading..." />
  //       </Box>
  //     );
  //   }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100svh",
        position: "relative",
      }}
    >
      <Box>
        {currentStep < 5 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <AnimatedImg
              src={Fox}
              alt="Step1"
              style={{ width: "120px", height: "100%" }}
            />
            <AnimatedImg
              src={FoxTuto}
              alt="FoxTuto"
              style={{ width: "250px", height: "100%" }}
            />
          </Box>
        )}

        {currentStep >= 1 && currentStep < 3 && (
          <Box sx={{ marginTop: "10px" }}>
            <AnimatedTypography
              sx={{
                fontSize: "1rem",
                textAlign: "center",
                fontFamily: "Avenir",
                marginRight: "60px",
                marginLeft: "60px",
                fontWeight: "900",
                color: "white",
                letterSpacing: "-0.05rem",
                marginTop: "10px",
              }}
            >
              Entre ton numéro de téléphone pour recevoir un code SMS de
              vérification.
            </AnimatedTypography>
            <AnimatedImg src={Step1} alt="Step1" />
          </Box>
        )}

        {currentStep >= 2 && currentStep < 3 && (
          <Box sx={{ marginTop: "10px" }}>
            <AnimatedImg src={Step2} alt="Step2" />
          </Box>
        )}

        {currentStep >= 3 && currentStep < 5 && (
          <Box sx={{ marginTop: "20px" }}>
            <AnimatedTypography
              sx={{
                fontSize: "1rem",
                textAlign: "center",
                fontFamily: "Avenir",
                fontWeight: "900",
                color: "white",
                letterSpacing: "-0.05rem",
                marginTop: "10px",
              }}
            >
              Crée un code personnel pour te connecter.
            </AnimatedTypography>
            <AnimatedImg src={Step3} alt="Step3" />
            <Box sx={{ height: "20px" }} />
          </Box>
        )}

        {currentStep >= 4 && currentStep < 5 && (
          <Box sx={{ marginTop: "10px" }}>
            <AnimatedTypography
              sx={{
                fontSize: "1rem",
                textAlign: "center",
                fontFamily: "Avenir",
                fontWeight: "900",
                marginRight: "60px",
                marginLeft: "60px",
                color: "white",
                letterSpacing: "-0.05rem",
                marginTop: "10px",
              }}
            >
              Saisis le numéro de la personne pour savoir si elle a aussi
              enregistré le tien.
            </AnimatedTypography>
            <AnimatedImg src={Step4} alt="Step4" />
            <Box sx={{ height: "100px" }} />
          </Box>
        )}

        {currentStep === 5 && <Step5Tutoriel />}
        {currentStep >= 6 && <Step6Tutoriel/>}
      </Box>

      {showStartButton && (
        <Box
          sx={{
            position: "fixed",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
          }}
        >
          <Button
            onClick={handleButtonClick}
            sx={{
              borderRadius: "13px",
              padding: "5px 20px",
              border: "3px solid rgba(253, 127, 50, 1)",
              textTransform: "none",
              display: "flex",
              margin: "auto",
              justifyContent: "center",
              backdropFilter: "blur(10px)",
              alignItems: "center",
              backgroundColor: "#ffbd59",
              color: "white",
              "&:hover":
                currentStep < 6
                  ? {
                      backgroundColor: "#ffbd59", // Couleur au survol pour les étapes 1 à 3
                    }
                  : {},
            }}
          >
            <Typography
              marginTop={1}
              fontFamily={"PetitCochon"}
              fontSize={25}
              color="white"
              letterSpacing={2}
              opacity={0.9}
              fontWeight="600"
            >
              {currentStep < 6 ? "SUIVANT" : t("getStarted")}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Tutorial;
