import React, { useState } from 'react';
import { Dialog, DialogContent, Typography, Box } from '@mui/material';
import AnimatedTextGradient from '../RevealDashboard/AnimatedTextGradient';
import Fox from '../assets/2.svg';

const ContributorsPopup = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        sx={{ color: '#ffbd59', fontSize: '0.8rem', textAlign: 'left', fontWeight: 'bold', cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        Contributeurs
      </Typography>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            <AnimatedTextGradient
                text="Contributeurs"
                colorone="#ffbd59"
                weight={900}
                colortwo="black"
                size="1.5rem"
            />
            <Box sx={{ height: '20px' }}/>
            {/* <Typography>@jcluzet</Typography> */}
            <AnimatedTextGradient
                text="Project Lead"
                colorone="black"
                colortwo="black"
                size="0.75rem"
            />
            <Box sx={{ height: '2px' }}/>

            <Typography
                sx={{ color: 'black', fontSize: '0.8rem', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => window.open('https://www.cluzet.fr/')}
            >Jo</Typography>
            <Box sx={{ height: '20px' }}/>
            <AnimatedTextGradient
                text="Character Designer"
                colorone="black"
                colortwo="black"
                size="0.75rem"
            />
            <Box sx={{ height: '2px' }}/>
            <Typography
                sx={{ color: 'black', fontSize: '0.8rem', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => window.open('https://www.instagram.com/louise_ctx_art/')}
            >@louise_ctx_art</Typography>
            <Box sx={{ height: '20px' }}/>
            <img src={Fox} alt="fox" style={{ width: '60px' }}/>
          </Box>

        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContributorsPopup;
