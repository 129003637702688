import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { toast } from "react-toastify";
import { useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";

const GlowAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled("div")`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffbd59;
  width: 24px;
  margin-left: 15px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
`;

const ShakeAnimation = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-2px, 0px) rotate(1deg); }
    30% { transform: translate(1px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-1px, 1px) rotate(0deg); }
    70% { transform: translate(1px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(0deg); }
`;

const CellWrapper = styled.div`
  //   width: 75vmin;
  //   height: 75vmin;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Lock = styled.div`
  cursor: pointer;
  font-size: 8vmin;
  z-index: 2;

  ${(props) =>
    props.$isShaking &&
    css`
      animation: ${ShakeAnimation} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97)
        both;
    `}
  ${(props) => (props.opacity ? "opacity: 1;" : "opacity: 0.5;")}
  animation: ${(props) =>
    props.$validPhone &&
    css`
      ${GlowAnimation} 2s ease-in-out infinite
    `};
`;

const AnimatedComponent = (props) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const [loading, setLoading] = useState(false);

  //   console.log("CAN CHANGE>> " + props.canChange);

  useEffect(() => {
    if (props.canChange) {
      setIsLocked(false);
    } else {
      setIsLocked(true);
    }
    if (props.validPhone) {
      // setIsLocked(!isLocked);
      setOpacity(true);
      if (!props.canChange) setOpacity(false);
    } else setOpacity(false);
  }, [props.validPhone, props.canChange]);

  const handleClick = async () => {
    setLoading(true);
    if (props.validPhone) {
      if (!props.canChange) {
        toast.error(
          "You need to wait ending time to change your locked number"
        );
        setLoading(false);
        return;
      }

      try {
        // axios to /api/can-lock with token & number of relation
        await axiosInstance.post("/api/v1/can-lock", {
          token: localStorage.getItem("token"),
          relation: props.phoneNumber,
        });
        // console.log("result");
        // console.log(result);
        setIsLocked(!isLocked);
        setLoading(false);

        props.onStepChange(isLocked ? 1 : 2);
      } catch (err) {
        toast.error(
          err.response.data.error ||
            "Une erreur est survenu en tentant de verrouiller ce numéro"
        );
        setIsShaking(true);
        setTimeout(() => {
          setIsShaking(false);
        }, 1000);
        setLoading(false);
      }
      // console.log result

      // setOpacity(!opacity);
    } else {
      setIsShaking(true);
      if (!props.canChange) {
        toast.error("Vous avez déjà verrouiller ce numéro.");
      } else toast.error("Ceci n'est pas un numéro valide 😅");
      setLoading(false);
      setTimeout(() => {
        setIsShaking(false);
      }, 1000);
    }
  };

  return (
    <CellWrapper onClick={handleClick}>
      <Lock
        $validPhone={props.validPhone}
        opacity={Number(opacity)}
        $isShaking={isShaking}
      >
        {loading ? <Loader /> : isLocked ? "🔒" : "🔓"}
      </Lock>
    </CellWrapper>
  );
};

export default AnimatedComponent;
