import React from 'react';
import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    // transform: translateY(20px);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
`;

const AnimatedText = styled.div`
  background: linear-gradient(90deg, ${props => props.colorone}, ${props => props.colortwo}, ${props => props.colorone});
  background-size: 200% auto;
  color: transparent;
  font-family: ${props => props.fontFamily};
  background-clip: text;
  text-fill-color: transparent;
  letter-spacing: ${props => props.letterSpacing};
  max-width: ${props => props.maxWidth};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradient} 5s linear infinite, ${fadeIn} 1s ease forwards ${props => props.delay || 0}s;
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  opacity: 0; // Initialiser l'opacité à 0
`;

const AnimatedTextGradient = ({ text, colorone, colortwo, size, maxWidth, weight = 800, fontFamily = "Avenir", letterSpacing = "0", delay }) => {
  return <AnimatedText colorone={colorone} colortwo={colortwo} size={size} maxWidth={maxWidth} weight={weight} fontFamily={fontFamily} letterSpacing={letterSpacing} delay={delay}>{text}</AnimatedText>;
};


export default AnimatedTextGradient;
