import React from 'react';
import { Box, Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import Step5 from "../assets/Tutorial/step5.svg";
import AnimatedTextGradient from '../RevealDashboard/AnimatedTextGradient';
import Button from '@mui/material/Button';


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedImg = styled.img`
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const AnimatedTypography = styled(Typography)`
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const AnimatedTextGradientAnim = styled(AnimatedTextGradient)`
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const AnimatedButton = styled(Button)`
  opacity: 0;
  animation: ${fadeIn} 1s ease forwards;
  animation-delay: ${props => props.delay || 0}s;
`;

const Step5Tutoriel = () => {
  return (
    <Box sx={{ marginTop: '20px' }}>
      <AnimatedTypography
        delay={0}
        sx={{
          fontSize: '1.2rem',
          textAlign: 'center',
          fontFamily: 'Avenir',
          fontWeight: '900',
          marginRight: '60px',
          marginLeft: '60px',
          color: 'white',
          marginBottom: '10px',
          letterSpacing: '-0.05rem',
          marginTop: '10px',
        }}
      >
        Découvre directement si c'est un match ou non.
      </AnimatedTypography>
      <AnimatedImg src={Step5} alt="Step5" delay={0.3} />
      <Box sx={{ textAlign: 'center' }}>
        <AnimatedTextGradientAnim
          text="MATCH"
          colorone="rgba(237,134,70,1)"
          colortwo="#ffbd59"
          fontFamily="PetitCochon"
          letterSpacing="0.1rem"
          weight={900}
          size="2rem"
          delay={1.1}
        />
        <AnimatedTypography
          delay={1.4}
          sx={{
            fontSize: '1rem',
            textAlign: 'center',
            fontFamily: 'Avenir',
            fontWeight: '900',
            marginRight: '15px',
            marginLeft: '15px',
            color: 'white',
            letterSpacing: '-0.05rem',
            marginTop: '10px',
          }}
        >
          Vous découvrez la date et l'heure à laquelle votre contact à rentré votre numéro.
        </AnimatedTypography>
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <AnimatedTextGradientAnim
          text="NO MATCH"
          colortwo="rgba(237,134,70,1)"
          colorone="#c23a00"
          fontFamily="PetitCochon"
          letterSpacing="0.1rem"
          weight={900}
          size="2rem"
          delay={2.5}
        />
        <AnimatedTypography
          delay={3}
          sx={{
            fontSize: '1rem',
            textAlign: 'center',
            fontFamily: 'Avenir',
            fontWeight: '900',
            marginRight: '15px',
            marginLeft: '15px',
            color: 'white',
            letterSpacing: '-0.05rem',
            marginTop: '10px',
          }}
        >
          MissMyEx vient d'être lancé, il est possible que votre contact ne l'ait pas encore utilisé.
        </AnimatedTypography>
          <Box sx={{ height: '10px' }} />
          <AnimatedTypography
          delay={3.2}
          sx={{
            fontSize: '1rem',
            textAlign: 'center',
            fontFamily: 'Avenir',
            fontWeight: '900',
            marginRight: '15px',
            marginLeft: '15px',
            color: 'white',
            letterSpacing: '-0.05rem',
            marginTop: '10px',
          }}
        >
          Si c'est le cas, revenez régulièrement pour voir si c'est un match.
        </AnimatedTypography>
          
          <Box sx={{ height: '10px' }} />
          <AnimatedTypography
          delay={3.5}
          sx={{
            fontSize: '1rem',
            textAlign: 'center',
            fontFamily: 'Avenir',
            fontWeight: '900',
            marginRight: '15px',
            marginLeft: '15px',
            color: 'white',
            letterSpacing: '-0.05rem',
            marginTop: '10px',
          }}
        >
          Ou vous pouvez activer une alerte SMS pour être prévenu immédiatement.
        </AnimatedTypography>
        <Box sx={{ height: '100px' }} />
      </Box>
    </Box>
  );
};

export default Step5Tutoriel;
