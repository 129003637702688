import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation de rotation, d'agrandissement et d'arrivée
const arriveAndSpin = keyframes`
  0% { transform: scale(0.1) rotate(0deg); opacity: 0; }
  100% { transform: scale(1) rotate(360deg); opacity: 1; }
`;

// Animation de brillance
const shine = keyframes`
  0% { filter: brightness(100%); }
  50% { filter: brightness(150%); }
  100% { filter: brightness(100%); }
`;

const EmojiContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  width: 80px;  /* Ajustez selon votre besoin */
  height: 80px; /* Ajustez selon votre besoin */
  position: relative;
  overflow: hidden;  /* S'assurer que l'émoji ne déborde pas du conteneur */
`;

const AnimatedEmoji = styled.span`
  animation: 
    ${arriveAndSpin} 2s ease forwards, 
    ${shine} 2s ease-in-out infinite;
`;

const KeyEmoji = ({ isVisible, emoji }) => {
    return (
      <EmojiContainer>
        {isVisible && (
          <AnimatedEmoji role="img" aria-label="key">
            {emoji}
          </AnimatedEmoji>
        )}
      </EmojiContainer>
    );
  };

export default KeyEmoji;
