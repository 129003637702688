import React from "react";
import Button from "@mui/material/Button";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Controller, useForm } from "react-hook-form";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { funnyErrors } from "../utils/funnyErrors";
import Box from "@mui/material/Box";
import { sendOtpRequest } from "../OTPRequests/sendOTP";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import Foxy from "../assets/FoxyCheck.png";
import { useTranslation } from "react-i18next";

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const AreYouSureText = styled(Typography)`
  animation: ${pulse} 1s infinite;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled("div")`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffbd59;
  width: 24px;
  height: 24px;
  animation: ${spin} 1s linear infinite;
`;

function NextButton({
  isConfirmationMode,
  setIsConfirmationMode,
  onSubmit,
  isValidNumber,
  dataRef,
  isLoading, // nouvel état pour gérer le chargement
}) {
  const { t } = useTranslation();

  const handleClick = async () => {
    // console.log("handleClick called");
    if (isConfirmationMode) {
      //   console.log("Submitting...");
      await onSubmit(dataRef.current); // await pour attendre la fin de la requête
    } else {
      setIsConfirmationMode(true);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: "-5px" }}>
      {isConfirmationMode ? (
        <>
          <Button
            onClick={() => setIsConfirmationMode(false)}
            style={{
              fontFamily: "PetitCochon",
              marginRight: "10px",
              color: "white",
              fontSize: "1.7rem",
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleClick}
            style={{
              fontFamily: "PetitCochon",
              color: "#ffbd59",
              fontSize: "1.5rem",
            }}
          >
            {isLoading ? <Loader /> : t("next")}
          </Button>
        </>
      ) : (
        <IconButton
          type="button"
          onClick={handleClick}
          sx={{
            visibility:
              isValidNumber || isConfirmationMode ? "visible" : "hidden",
          }}
        >
          <Typography
            sx={{
              fontSize: "2.0rem",
              textAlign: "center",
              fontFamily: "PetitCochon",
              color: isConfirmationMode ? "red" : "#ffbd59",
              marginTop: "-10px",
            }}
          >
            {t("next")}
          </Typography>
        </IconButton>
      )}
    </div>
  );
}

const PhoneNumberInput = React.memo(({ onNext, placeholder, showError }) => {
  const { t } = useTranslation();
  const [showEmoji, setShowEmoji] = React.useState(false);
  const [isConfirmationMode, setIsConfirmationMode] = React.useState(false);
  const formDataRef = React.useRef(null);
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  //   const handleOpenDialog = () => {
  //     setOpenDialog(true);
  //   };

  const popOut = keyframes`
  0% { opacity: 0; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0; transform: translateY(-40px) scale(0.8); }
`;

  const StyledMuiTelInput = styled(MuiTelInput)`
    & .MuiTelInput-TextField {
      border: 1px solid white;

      &:hover {
        border-color: white;
      }

      &.Mui-focused {
        border-color: white;
      }
    }

    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: white;
      }
      &:hover fieldset {
        border-color: white;
      }
      &.Mui-focused fieldset {
        border-color: white;
      }
    }

    .MuiInputLabel-root {
      color: white;

      &.Mui-focused {
        color: white; // quand le champ est en focus
      }

      &.Mui-error {
        color: red; // vous pouvez conserver le rouge ou choisir une autre couleur
      }
    }

    .MuiInputBase-input {
      &::placeholder {
        color: white;
        font-family: "PetitCochon", sans-serif;
        opacity: 1;
      }
    }

    & .MuiTelInput-Flag,
    & .MuiTelInput-IconButton {
      border-color: white;
    }

    & input {
      font-family: "PetitCochon", sans-serif;
      font-size: 1.5rem;
      color: white;
    }
  `;

  const EmojiPop = styled("div")`
    position: absolute;
    margin-bottom: 200px;
    z-index: 2;
    font-size: 1.5rem;
    animation: ${popOut} 0.8s forwards;
  `;

  const errorMessageContainerStyle = {
    width: "250px",
    fontSize: "0.6rem",
    marginTop: "5px",
    color: "red",
    textAlign: "center",
    whiteSpace: "nowrap",
    // overflow: 'hidden',
    textOverflow: "ellipsis",
  };

  const inputContainerStyle = {
    // width: "500px", // Ajustez selon vos besoins
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const [isValidNumber, setIsValidNumber] = React.useState(false);

  const handleNumberChange = (value) => {
    // console.log("handleNumberChange called with value:", value);
    // Convertir les numéros qui commencent par '0' en leur format international
    if (value.startsWith("+0")) {
      value = "+33" + value.slice(2);
    }

    // Mise à jour de la valeur du champ ici
    setValue("tel", value);

    const phoneNumber = parsePhoneNumberFromString(value, "FR");
    const isValid = phoneNumber?.isValid() || false;
    setIsValidNumber(isValid);
    formDataRef.current = { tel: value };

    if (isValid) {
      setPhoneNumber(phoneNumber.formatInternational());
      setShowEmoji(true);
      setTimeout(() => setShowEmoji(false), 800); // hide the emoji after the animation duration
    }
  };

  const getRandomError = () => {
    return funnyErrors[Math.floor(Math.random() * funnyErrors.length)];
  };

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      tel: "",
    },
  });

  const onSubmit = async (data) => {
    // console.log("onSubmit called with data:", data);
    setIsLoading(true); // déclenchez le chargement
    const phoneNumber = parsePhoneNumberFromString(data.tel, "FR");
    if (phoneNumber && phoneNumber.isValid()) {
      const formattedNumber = phoneNumber.number;
      const isSuccess = await sendOtpRequest(formattedNumber);
      setIsLoading(false); // terminez le chargement
      if (isSuccess) {
        onNext(formattedNumber);
      }
    } else {
      setIsLoading(false); // terminez le chargement
      toast.error("Ce numéro de téléphone n'est pas valide 😅");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px",
        padding: "20px",
      }}
    >
      <Controller
        name="tel"
        control={control}
        rules={{ validate: matchIsValidTel }}
        render={({ field, fieldState }) => (
          <div style={inputContainerStyle}>
            {!isConfirmationMode && (
              <Box sx={{ position: "fixed", bottom: "137px", left: "300px" }}>
                <img src={Foxy} alt="Foxy" style={{ width: "45%" }} />
              </Box>
            )}
            {isConfirmationMode ? (
              <>
                <Typography
                  sx={{
                    fontSize: "1.9rem",
                    textAlign: "center",
                    color: "white",
                    fontFamily: "PetitCochon",
                    marginBottom: "20px",
                    marginTop: "33px",
                  }}
                >
                  {phoneNumber}
                </Typography>
                <AreYouSureText
                  sx={{
                    fontSize: "1.5rem",
                    textAlign: "center",
                    fontFamily: "PetitCochon",
                    color: "#d94e4e",
                  }}
                >
                  {t("areYouSure")}
                </AreYouSureText>
              </>
            ) : (
              <StyledMuiTelInput
                {...field}
                label={t(placeholder)}
                defaultCountry="FR"
                error={!!(field.value && !isValidNumber)}
                onChange={(value) => {
                  handleNumberChange(value);
                  field.onChange(value);
                }}
              />
            )}

            <Box
              sx={{
                width: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: showError ? "0px" : "20px",
                visibility:
                  field.value.length > 4 && !isValidNumber
                    ? "visible"
                    : "hidden",
              }}
            >
              {showError && (
                <div style={errorMessageContainerStyle}>{getRandomError()}</div>
              )}
            </Box>

            <NextButton
              isValidNumber={isValidNumber}
              isConfirmationMode={isConfirmationMode}
              setIsConfirmationMode={setIsConfirmationMode}
              isLoading={isLoading}
              onSubmit={onSubmit}
              dataRef={formDataRef}
            />
            {isValidNumber && <>{showEmoji && <EmojiPop>💛</EmojiPop>}</>}
          </div>
        )}
      />
    </form>
  );
}
);

export default PhoneNumberInput;
