import React from "react";
import styled from "@emotion/styled";
import "./traductions/i18n";
import { Box } from "@mui/material";
// import { keyframes } from '@emotion/react';
import logo from "./assets/logo.svg";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import PhoneNumberInput from "./PhoneInput/PhoneNumber";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { keyframes } from "@emotion/react";
import { PhoneConfirmation } from "./PhoneInput/PhoneConfirmation";
import { AccountCreation } from "./Dashboard/AccountCreation";
import ArrowForwardIcon from "@mui/icons-material/ArrowUpward";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Tilt from "react-parallax-tilt";
import Foxy from "./assets/FoxyCheck.png";
import Dashboard from "./Dashboard/Dashboard";
import FoxHelper from "./Intro/FoxHelper";
import Tutorial from "./Tutorial/Tutorial";
import { useRef } from "react";
import StartButton from "./Tutorial/startButton";
import FooterCGV from "./Footer/Footer";
// import ChatBot from "./Footer/ChatBot";

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

const Footer = styled(Typography)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: "#ffbd59";
  fontsize: "0.8rem";
  fontweight: "bold";
`;

const enterFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const exitToTop = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const AnimatedContainer = styled.div`
  animation: ${(props) => (props.exit ? exitToTop : enterFromBottom)} 0.5s
    forwards;
`;

const AnimatedFadeInContainer = styled.div`
  animation: ${enterFromBottom} 0.5s forwards;
`;

const drop = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(20vmin);
    opacity: 0;
  }
`;

const Tear = styled("span")`
  font-size: 5vmin;
  position: fixed;
  z-index: 0;
  left: 75%; // Ajustez cette valeur pour positionner la larme
  top: 10%; // Ajustez cette valeur pour commencer la larme d'un point spécifique
  animation: ${drop} 2s infinite;
`;

const BackgroundContainer = styled.div`
  background: linear-gradient(
    10deg,
    #7f4bf9,
    #7f3ea9,
    #7f4bf9 0%,
    #7f3ea9,
    #7f4bf9
  );
  min-height: 100svh;
  overflow: auto;
`;

const AppContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Changez ceci pour répartir l'espace
  align-items: center;
  min-height: 100svh; // Utilisez min-height au lieu de height
  max-width: none !important;
  padding: 5px;
  width: 100vw;
  font-size: calc(10px + 2vmin);
`;

const MiddleContainer = styled.div``;

const AppLogo = styled("img")`
  height: 35vmin;
  pointer-events: none;
  z-index: 1;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 20%; // Supposons que votre logo ait une hauteur de 60px
  left: 0;
  width: 100%;
  height: calc(
    100% - 20%
  ); // Le modal commence en dessous du logo et s'étend jusqu'au bas
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  animation: ${(props) => (props.exiting ? slideDown : slideUp)} 0.5s
    cubic-bezier(0.5, 0, 0, 1) forwards;
  border-radius: 20px 20px 0 0; // Coins arrondis uniquement en haut
`;

const ModalContent = styled.div`
  //   background-color: rgba(255, 255, 255, 0.8); // Arrière-plan translucide
  width: 100%; // Prend toute la largeur de l'écran
  //   background-color: rgba(255, 255, 255, 0.2);
  height: 100%; // Occupe tout l'espace de ModalContainer
  //   padding: 2px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  border-radius: 20px 20px 0 0; // Coins arrondis uniquement en haut
`;

const CloseButton = styled.span`
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 10;
`;
function Modal({ onClose, exiting }) {
  const [showDetails, setShowDetails] = React.useState(false);
  const modalRef = useRef(); // Référence pour le contenu du modal

  // Gestionnaire d'événements pour fermer le modal en cliquant en dehors
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose(); // Fermer le modal si le clic est en dehors
    }
  };

  useEffect(() => {
    // Ajouter l'écouteur d'événements lors du montage du composant
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Nettoyer l'écouteur lors du démontage du composant
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { t } = useTranslation();

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <ModalContainer exiting={exiting}>
      <ModalContent ref={modalRef}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            // paddingTop: "2rem",
            backdropFilter: "blur(6px)",
            paddingBottom: "2rem",
            maxWidth: "700px",
            margin: "0 auto",
            width: "100%",
            // borderRadius: "20px",
            boxShadow: "0 0 100px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <CloseButton onClick={onClose}>
            <Typography fontFamily={"PetitCochon"} fontSize={35} color="#333">
              X
            </Typography>
          </CloseButton>
          <Typography
            fontFamily={"PetitCochon"}
            fontSize={40}
            color="#333"
            textAlign="center"
            marginBottom="2rem"
          ></Typography>

          {[1, 2, 3, 4].map((step) => (
            <Box
              key={step}
              sx={{
                width: "80%",
                padding: "1rem 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                fontFamily={"PetitCochon"}
                fontSize={35}
                color="#ffbd59"
                marginLeft={-1}
                marginRight={2}
              >
                {step}
              </Typography>
              <Typography
                fontFamily={"Verdana, Geneva, sans-serif"}
                fontSize="18px"
                color="#555"
              >
                {t(`step${step}`)}
              </Typography>
            </Box>
          ))}

          <Box
            sx={{
              width: "80%",
              padding: "1rem",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              borderRadius: "10px",
              border: "1px solid #ddd",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              fontFamily={"PetitCochon"}
              fontSize={30}
              color="#ffbd59"
              marginBottom={1}
            >
              🔐
            </Typography>
            <Typography
              fontFamily={"Verdana, Geneva, sans-serif"}
              fontSize="16px"
              color="#555"
              textAlign="center"
            >
              {t("privacyInfo")}
            </Typography>
            <div
              onClick={handleToggleDetails}
              style={{
                marginTop: "0.5rem",
                textDecoration: "underline",
                cursor: "pointer",
                color: "#007BFF",
                display: "flex",
                alignItems: "center",
              }}
            >
              En apprendre plus
              {showDetails ? (
                <span style={{ marginLeft: "5px" }}>▲</span>
              ) : (
                <span style={{ marginLeft: "5px" }}>▼</span>
              )}
            </div>
            {showDetails && (
              <Typography
                fontFamily={"Verdana, Geneva, sans-serif"}
                fontSize="16px"
                color="#555"
                textAlign="center"
                marginTop="0.5rem"
              >
                {t("privacyDetails")}
              </Typography>
            )}
          </Box>
        </Box>
      </ModalContent>
    </ModalContainer>
  );
}


function App() {
  const [step, setStep] = useState(-1);
  const [phoneNumber, setPhoneNumber] = useState(null); // Ajoutez cet état
  const [phoneNumberRegister, setPhoneNumberRegister] = useState(null); // Ajoutez cet état
  const [exitingStep, setExitingStep] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [exiting, setExiting] = useState(false);
  const { t } = useTranslation();

  const renderWithTilt = (children) => {
    if (isMobileDevice()) {
      return <>{children}</>;
    }
    return (
      <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} scale={1.05}>
        {children}
      </Tilt>
    );
  };

  const [contentTooLong, setContentTooLong] = useState(false);

  useEffect(() => {
    // Détectez si le contenu dépasse la hauteur de la fenêtre
    console.log(`
 _____ _         _____     _____     
 |     |_|___ ___|     |_ _|   __|_ _ 
 | | | | |_ -|_ -| | | | | |   __|_'_|
 |_|_|_|_|___|___|_|_|_|_  |_____|_,_|
                       |___|          

 Are you looking for cookies? 🍪
 a @jocluzet website
    `);

    const handleResize = () => {
      setContentTooLong(window.innerHeight < document.body.offsetHeight);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCloseModal = () => {
    setExiting(true);
    setTimeout(() => {
      setShowModal(false);
      setExiting(false);
    }, 500);
  };

  useEffect(() => {
    // if localStorage have a phoneNumber, then we can skip the first step, and go to the second one
    const phoneNumberRegister = localStorage.getItem("phoneNumber");
    if (phoneNumberRegister) {
      setPhoneNumber(phoneNumberRegister);
      setPhoneNumberRegister(true);
      setStep(2);
    } else {
      setPhoneNumberRegister(false);
    }
    // setStep(4);
  }, [phoneNumberRegister]);

  const handleNext = (nextStep) => {
    setExitingStep(step);
    setTimeout(() => {
      setStep(nextStep);
      setExitingStep(null);
    }, 200); // C'est la durée de votre animation, ici 0.5s
  };

  return (
    <>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
      {showModal && (
        <Modal
          onClose={handleCloseModal}
          exiting={exiting}
          setShowModal={setShowModal}
          showModal={showModal}
        ></Modal>
      )}
      <BackgroundContainer>
        <AppContainer sx={{ height: "100%" }}>
          {step !== 0 && renderWithTilt(<AppLogo src={logo} alt="logo" />)}
          {/* {step == 0 && <Tear>💧</Tear>} */}
          <MiddleContainer>
            {step === -1 &&
              renderWithTilt(
                <AnimatedContainer exit={exitingStep === 0}>
                  <FoxHelper
                    text="Hello, je me présente, DrLove. Si tu veux savoir comment ça marche, clique sur le bouton Tutoriel."
                    state={1}
                  />
                </AnimatedContainer>
              )}

            {step === 0 && <Tutorial handleNext={handleNext} />}

            {step === 1 && (
              <AnimatedContainer exit={exitingStep === 1}>
                {/* <Box sx={{ position: "fixed", bottom: "133px", left: "300px" }}>
                <img src={Foxy} alt="Foxy" style={{ width: "50%" }} />
                </Box> */}
                <PhoneNumberInput
                  placeholder={"phoneNumberLabel"}
                  showError={false}
                  onNext={(number) => {
                    setPhoneNumber(number); // Capturez le numéro de téléphone
                    handleNext(2);
                  }}
                />
              </AnimatedContainer>

            )}

            {step === 2 && (
              <Box sx={{ marginTop: "-50%" }}>
                {phoneNumberRegister && (
                  <AnimatedFadeInContainer>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "PetitCochon",
                        color: "#ffbd59",
                        textAlign: "center",
                        fontSize: "1.5rem",
                        marginBottom: "15px",
                      }}
                    >
                      {t("welcomeBack")} 👋
                    </Typography>
                  </AnimatedFadeInContainer>
                )}
                <AnimatedContainer exit={exitingStep === 2}>
                  <PhoneConfirmation
                    phoneNumber={phoneNumber}
                    onNext={handleNext}
                  />
                </AnimatedContainer>
                {/* <Box sx={{ height: "0px" }}></Box> */}
              </Box>
            )}

            {step === 3 && (
              <>
                <AnimatedFadeInContainer>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "PetitCochon",
                      color: "white",
                      textAlign: "center",
                      fontSize: "1.5rem",
                      marginBottom: "15px",
                    }}
                  >
                    {t("lastStep")}
                  </Typography>
                </AnimatedFadeInContainer>
                <AnimatedContainer exit={exitingStep === 3}>
                  <AccountCreation
                    phoneNumber={phoneNumber}
                    onNext={() => handleNext(4)}
                  />
                </AnimatedContainer>
              </>
            )}

            {step === 4 && (
              <Box sx={{ marginBottom: "10%" }}>
                <AnimatedContainer>
                  <Dashboard />
                </AnimatedContainer>
              </Box>
            )}
          </MiddleContainer>

          {step === -1 ? (
            <StartButton handleNext={handleNext} />
          ) : (
            step !== 0 && (
              <FooterCGV setShowModal={setShowModal} showModal={showModal} />
            )
          )}
        </AppContainer>
      </BackgroundContainer>
    </>
  );
}

export default App;
