// create a default dashboard component
import React from "react";
import { Box, Typography } from "@mui/material";
import AnimatedComponent from "../PhoneInput/AnimatedComponent";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import SimplePhoneNumberInput from "../PhoneInput/simplePhoneNumberInput";
import Button from "@mui/material/Button";
import KeyEmoji from "../PhoneInput/KeyEmoji";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Tilt from "react-parallax-tilt";
import Card from "@mui/material/Card";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import axiosInstance from "../utils/axiosInstance";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ConfirmationDialog from "../PhoneInput/confirmLockDialog";
import styled from "@mui/material/styles/styled";
import AnimatedTextGradient from "../RevealDashboard/AnimatedTextGradient";
import PaymentDialog from "../Payment/PaiementDialog";
import AnimatedBox from "../RevealDashboard/AnimatedBox";
import AnimatedBoxSMS from "../RevealDashboard/AnimatedBoxSMS";
import RevealAnimation from "./RevealAnimation";
import { keyframes } from "@emotion/react";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled("div")`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffbd59;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

// Création d'une Box personnalisée avec un fond translucide
const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const Recap = ({ relations }) => {
//   console.log(relations[0].createdAt);
  function formatDate(dateString) {
    if (!dateString) return "Date not available"; // Gère les cas où dateString est null/undefined
    const date = new Date(dateString);
    return date.toLocaleString();
  }

  const timeSinceCreation = (date) => {
    // calculate the time since the creation of the relation
    const creationDate = new Date(date);
    const currentDate = new Date();
    const diff = currentDate - creationDate;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return years + " ans";
    }
    if (months > 0) {
      return months + " mois";
    }
    if (days > 0) {
      return days + " jours";
    }
    if (hours > 0) {
      return hours + " heures";
    }
    if (minutes > 0) {
      return minutes + "min";
    }
    return "quelques secondes";
  };

  const MyComponent = ({ relations }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
      setOpenDialog(true);
    };

    const handleClose = () => {
      setOpenDialog(false);
    };

    const [loading, setLoading] = useState(false);
    const handlePay = async () => {};

    return (
      <StyledBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          //   marginTop: "5vh",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            color: "white",
            marginBottom: 2,
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          {relations[0].existingRelation ? (
            <AnimatedBox match={true} sms={relations[0].alertBySms} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <AnimatedBox match={false} />

              <Box sx={{ height: "23px" }} />
              <AnimatedBoxSMS active={relations[0].alertBySms} />

              {!relations[0].alertBySms && (
                <>
                  <Box sx={{ height: "8px" }} />
                  <Box sx={{ height: "0px" }} />
                </>
              )}
            </Box>
          )}
        </Typography>

        {relations[0].existingRelation && (
          <>
            <AnimatedTextGradient
              text={`Ce numéro à rentré le votre à cette date:`}
              colorone="white"
              colortwo="white"
              size="0.7rem"
            />
            <Box sx={{ height: "5px" }} />
            <AnimatedTextGradient
              text={`${
                relations[0] && relations[0].updatedAt
                  ? formatDate(relations[0].updatedAt)
                  : "Loading..."
              }`}
              colorone="rgb(245, 192, 106)"
              colortwo="rgba(237,134,70,1)"
              size="0.8rem"
              weight={900}
            />
          </>
        )}
        <PaymentDialog open={openDialog} onClose={handleClose} />
      </StyledBox>
    );
  };
  return <MyComponent relations={relations} />;
};

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

const Dashboard = () => {
  const [step, setStep] = React.useState(1);
  const [validPhone, setValidPhone] = React.useState(false);
  const [canEdit, setCanEdit] = React.useState(true);
  const [loading, setLoading] = React.useState(true); // nouvel état pour gérer le chargement
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [emoji, setEmoji] = useState("🔎");
  const [animationUnbox, setAnimationUnbox] = useState(false);
  const [canChange, setCanChange] = useState(true); // nouvel état pour gérer le chargement
  const [relations, setRelations] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [wait, setWait] = useState(false);

  const renderWithTilt = (children) => {
    if (isMobileDevice()) {
      return <>{children}</>;
    }
    return (
      <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} scale={1.05}>
        {children}
      </Tilt>
    );
  };

  const onStepChange = (step) => {
    // console.log(step);
    setStep(step);
    setCanEdit(step === 1);
    if (step === 2) {
      setOpenDialog(true);
    }
    if (step === 1) {
      setCanChange(true);
    }
  };

  useEffect(() => {
    const fetchRelations = async () => {
      try {
        const token = localStorage.getItem("token");

        // console.log("token send to get-relations: ", token);
        // Assurez-vous d'ajuster l'URL en fonction de vos besoins
        const response = await axiosInstance.post("/api/v1/get-relations", {
          token,
        });

        if (response.data.success) {
          setRelations(response.data.relations);
          // if there is at least 1 relation, set the emoji to green
          // if the relation is very recent ( less than 10 seconds)
            if (
                response.data.relations.length > 0 
                && new Date() - new Date(response.data.relations[0].createdAt) < 10000
            ) {
                setAnimationUnbox(true);
                setTimeout(() => setAnimationUnbox(false), 3000);
            }

                
          if (response.data.relations.length > 0) {
            setPhoneNumber(response.data.relations[0].relationNumber);
            // console.log(
            //   "SETTING! " + response.data.relations[0].relationNumber
            // );
            setCanChange(false);
          }
          // Si des relations existent, ajustez votre état/UI ici en conséquence
          // Ex: pré-remplir le numéro de téléphone, changer l'emoji, etc.
        //   console.log(response.data.relations);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des relations:", error);
        setLoading(false);
        // Gérer l'erreur ici (ex: afficher un message d'erreur)
      }
    };

    fetchRelations();
  }, [step]);

  const onNumberValid = (number) => {
    // remove all space from number
    // console.log(number);
    if (number.length < 10) {
      setValidPhone(false);
      return;
    }
    const phoneNumber = parsePhoneNumberFromString(number, "FR");
    if (phoneNumber && phoneNumber.isValid()) {
      setValidPhone(true);
      let formattedNumber = phoneNumber.formatInternational();
      formattedNumber = formattedNumber.replace(/\s/g, "");
      setPhoneNumber(formattedNumber);
    }
  };

  const handleClick = async () => {
    // console.log("handleClick called");
    setWait(true);
    if (!validPhone || !canChange) return;
    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.post("/api/v1/lock-number", {
        token,
        relation: phoneNumber,
      });


      // En cas de succès, vérifiez la réponse et mettez à jour l'emoji en conséquence
      if (response.data.success) {
          handleCloseDialog();
          setStep(3);
          setCanChange(false);
          // JO TU CHECK ICI
          setWait(false);
      } else {
        // Traitez les cas où le succès est false ou n'est pas présent
        setWait(false);
        throw new Error(response.data.error || "Une erreur est survenue");
      }
    } catch (error) {
      // Affichez l'erreur avec un toast
      toast.error(`Erreur : ${error.message}`);
        setWait(false);
        handleCloseDialog();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setStep(1);
    setCanEdit(true);
    setCanChange(true);
    setPhoneNumber("");
    setValidPhone(false);
    // setEmoji("🔓");
  };

  if(wait)
  {
    return(
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "rgba(0,0,0,0.20)",
            borderRadius: "20px",
            padding: "50px",
            // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "370px",
              gap: "20px",
              justifyContent: "center",
            }}
          >
                
            <Typography variant="h5" component="div" sx={{ color: "black", fontFamily: "PetitCochon", fontSize: "2rem", fontWeight: "bold" , color: "#ffbd59"}}>
                On vérifie ... 🧙‍♂️
            </Typography>
            <Loader/>
            </Box>
        </Card>
    )
  }

  if (loading) {
    return(

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "5vh" }}>
        <CircularProgress
              size={54}
              style={{
                color: "#e4bb07",
              }}
            />
    </Box>
            )
    }

  return (
    <Box>
      {renderWithTilt(
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#FFFFFF30",
            borderRadius: "20px",
            padding: "5px",
            // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "370px",
              justifyContent: "center",
            }}
          >
            {/* <div style={{ display: 'grid', placeItems: 'center', margin: '0' }}> */}
            <SimplePhoneNumberInput
              onNumberValid={onNumberValid}
              canEdit={canEdit && canChange}
              number={phoneNumber}
            />
            <Box sx={{ marginRight: "35px", marginLeft: "-5px" }}>
              <AnimatedComponent
                step={step}
                canChange={canChange}
                onStepChange={onStepChange}
                validPhone={validPhone}
                phoneNumber={phoneNumber}
              />
            </Box>
          </Box>
          <ConfirmationDialog
            number={phoneNumber}
            open={openDialog}
            Confirm={handleClick}
            handleClose={() => handleCloseDialog()}
          />
          {
            animationUnbox && (
            <>
            <Box sx={{ height: "290px" }} >
                <RevealAnimation match={relations[0].existingRelation} />
            </Box>
            </>
                )

          }
          {relations.length > 0 && !animationUnbox && (
            <>
              <Recap relations={relations} />
            </>
          )}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                margin: "auto",
                justifyContent: "center",
                zIndex: "100",
              }}
            >
              <Box
                sx={{ cursor: step === 2 ? "pointer" : "default" }}
                onClick={() => {
                  if (step === 2) {
                    setOpenDialog(true);
                  }
                }}
              >
                {relations.length == 0 && !validPhone && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <AnimatedTextGradient
                      text="Rentrez ici le numéro de votre contact"
                      colorone="white"
                      colortwo="white"
                      size="1.1rem"
                    />
                    <AnimatedTextGradient
                      text="Si celui ci n'a pas entré votre numéro ici, il ne sera jamais alerté"
                      colorone="white"
                      colortwo="white"
                      size="0.6rem"
                    />
                    <Box sx={{ height: "0px", paddingBottom: "5px" }} />
                    <Box sx={{ height: "5px" }} />
                  </Box>
                )}
                {validPhone && (
                  <>
                    <AnimatedTextGradient
                      text="Confirmez en cliquant sur le cadenas"
                      colorone="white"
                      colortwo="#e4bb07"
                      weight={900}
                      size="1rem"
                    />
                    <Box sx={{ height: "10px" }} />
                  </>
                )}
                {step === 2 && (
                  <KeyEmoji isVisible={step === 2} emoji={emoji} />
                )}
              </Box>
            </Box>
          </Box>
        </Card>
      )}
      {/* </div> */}
    </Box>
  );
};

export default Dashboard;
