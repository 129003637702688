export const funnyErrors = [
    "Is that number from Mars?",
    "Does your phone exist in a parallel universe?",
    "I've seen cats dial better numbers!",
    "Oops! That was a miscall.",
    "Beep Beep Boop... Nope, doesn't compute.",
    "I think your phone's in airplane mode.",
    "Did a monkey type that for you?",
    "I'm not a detective, but that doesn't seem right.",
    "Trying to call Hogwarts? Wrong number!",
    "Was that number in Morse code?",
    "Your phone might be feeling a bit shy.",
    "I believe you've just invented a new area code!",
    "Hold on, let me get my crystal ball...",
    "Did you type with your toes?",
    "I've heard of ghost calls, but this is ridiculous.",
    "Phone numbers usually have numbers, just saying...",
    "Your phone is not a time machine!",
    "Wrong dimension, try again.",
    "E.T., phone home. Oh, wait, wrong number!",
    "You're trying to dial the Batcave, aren't you?",
    "Sorry, that won't reach the North Pole.",
    "Maybe try using numbers from this century?",
    "Looks like you're trying to reach Middle-Earth. Wrong realm!",
    "You missed the target. Archery is next door!",
    "The aliens called, they want their number back.",
    "If that was a password, it'd be '1234'. Try again.",
    "That number rang a bell, but it's not right.",
    "Is that a secret code? It's too secret for me.",
    "Are you trying to communicate in an alien language?",
    "Numbers speak louder than words, but not this one.",
    "You've got the spirit, but not the digits!",
    "I asked for a number, not a magic spell!",
    "That's a funny way to spell 'error'.",
    "Sorry, that's not the hotline to superheroes.",
    "I'm pretty sure unicorns don't use phones.",
    "Ever tried a magic 8-ball? Might have better luck!",
    "The pigeon carriers are on strike, try a real number.",
    "If phones could laugh, yours just did.",
    "Your number is in another castle, Mario.",
    "Try again, but this time with more enthusiasm!",
    "That sounded like a song, not a phone number.",
    "Did you just mash the keyboard?",
    "I think that number is from a fairy tale.",
    "I wasn't expecting a number from Wonderland.",
    "For best results, use numbers from this galaxy.",
    "Try not to summon any ancient spirits with that.",
    "And for my next trick, I'll make that number disappear...",
    "Do you believe in magic? Because that wasn't a number.",
    "I've seen many numbers, but that's a first!",
    "Trying to call Narnia? Not today!",
    "Looks like a recipe for a magic potion, not a phone number.",
    "Even Sherlock couldn't figure that one out.",
    "That number's from Neverland, isn't it?",
    "Hold on, checking the matrix... Nope, not there.",
    "This isn't a bingo game, we need a real number!",
    "I hope you're not trying to text the Loch Ness Monster.",
    "Dialing that might summon a genie. Careful!",
    "Oh! Almost thought you found Atlantis' hotline.",
    "I'd bet the Minotaur wouldn't answer that.",
    "That's not even a number on the Fibonacci sequence.",
    "Looks like a Sudoku puzzle gone wrong.",
    "Maybe try not to invent your own numbers?",
    "Do you often communicate with mermen and mermaids?",
    "Was that a spell from Merlin's book?",
    "Morse code won't work here, try actual digits.",
    "Is this some kind of elvish?",
    "That looks like a constellation, not a phone number.",
    "Your creativity is impressive, but it's not helping.",
    "Did you just try calling a galaxy far, far away?",
    "Sorry, no intergalactic calls allowed.",
    "If you're trying to reach the moon, you're a few digits off.",
    "I almost mistook that for the Kraken's call sign.",
    "Channeling your inner wizard won't help here.",
    "Your number's on a quest to Mordor and can't be reached.",
    "Is that written in ancient runes?",
    "Trying to decode that... Nope, still an error.",
    "Even a time-turner can't make sense of that number.",
    "Your digits are dancing, but not in the right order.",
    "Is this number from a parallel universe?",
    "Sorry, we can't bridge calls to Asgard.",
    "That sounds more like a Wi-Fi password for gnomes.",
    "Did you learn that number in a dream?",
    "Hold on, consulting with the oracle... She's confused too.",
    "I'd need a magic mirror to decipher that.",
    "Looks like a code only a pirate could understand.",
    "Dumbledore might approve, but I can't.",
    "Even the Marauder's Map can't locate that number.",
    "The stars didn't align for that one.",
    "I've consulted every crystal ball, still an error.",
    "Even the Force can't recognize that sequence.",
    "Your phone might be haunted. Beware!",
    "Looks like a cryptic riddle, not a phone number.",
    "That's so cryptic, even the Sphinx is stumped.",
    "Is that a secret handshake in numeric form?",
    "Sounds like a number from a legend. Or just legendary confusion.",
    "Did a ghost whisper that sequence to you?",
    "Hold on, let me get my decoder ring...",
    "If this was a treasure hunt, you'd be off the map!",
    "Error! That's not in the scroll of ancient phone numbers.",
    "Only a phoenix might rise from that number.",
    "Might work in a fairy tale, but not here!",
  ];