import React, { useState, useEffect } from 'react';

const EmojiAnimation = () => {
    const [currentEmoji, setCurrentEmoji] = useState("🥁");
    const emojis = ["🥁", "🥁", "🥁"]; // Liste des emojis
    const totalDuration = 7000; // Durée totale de l'animation en millisecondes
    const singleDuration = 500; // Durée de chaque emoji

    useEffect(() => {
        let currentEmojiIndex = 0;
        const interval = setInterval(() => {
            currentEmojiIndex = (currentEmojiIndex + 1) % emojis.length;
            setCurrentEmoji(emojis[currentEmojiIndex]);
        }, singleDuration);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <style>
                {`
                    @keyframes grow {
                        from { transform: scale(3); }
                        to { transform: scale(20); }
                    }
                `}
            </style>
            <div style={styles.container}>
                <div style={{...styles.emoji, animation: `grow ${totalDuration}ms linear`}}>
                    {currentEmoji}
                </div>
            </div>
            {/* <div>
                fdfdfdFDFD
            </div> */}
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-20px',
        height: '100%', // Centrer verticalement dans la fenêtre
    },
    emoji: {
        fontSize: '2rem',
        animationIterationCount: '1',
    }
};

export default EmojiAnimation;
