import axiosInstance from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const sendOtpRequest = async (phoneNumber) => {
    try {
        const response = await axiosInstance.post("/api/v1/send-otp", {
            phoneNumber,
        });

        
        if (response.data?.validOtp === true) {
            toast.info(
                "Votre code envoyé par SMS il y a " +
                response.data.minutes +
                " min est encore valide."
                );
                return true;
            } else if (response.data?.success === true) {
                return true;
            }
            return false;
        } catch (error) {
            if (error.response && error.response.data) {
            // Handle specific error messages here
            toast.error(error.response.data.error || "Une erreur est survenue 😢");
        } else {
            console.error("Error sending OTP:", error);
            // General error handling for all other cases
            toast.error("Hmm... Notre serveur ne repond pas.. 🤕 Retentez dans quelques minutes..");
        }

        return false;
    }
};
