import React, { useState } from "react";
import { Dialog, DialogContent, Typography, Box } from "@mui/material";
import AnimatedTextGradient from "../RevealDashboard/AnimatedTextGradient";
import HereWhy from "../assets/HereWhy.svg";
import FoxWhy from "../assets/4.svg";

const CGVPopup = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        sx={{
          color: "#ffbd59",
          fontSize: "0.8rem",
          textAlign: "left",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        CGU
      </Typography>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: "1.5rem",
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Conditions Générales d'Utilisation
            </Typography>

            <Box
              sx={{
                width: "70%",
                margin: "auto",
                marginBottom: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={FoxWhy} alt="FoxWhy" style={{ width: "40%" }} />
              <img src={HereWhy} alt="HereWhy" style={{ width: "200px" }} />
            </Box>
            <Box
              sx={{
                border: "2px solid #ffbd59",
                marginBottom: "20px",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "#ffbd5920",
              }}
            >
              <AnimatedTextGradient
                text="Les CGU sont souvent un vrai casse-tête, alors on a décidé de te les simplifier"
                colorone="black"
                colortwo="#e68a00"
                size="1.2rem"
                weight={900}
              />
              <AnimatedTextGradient
                text="(On a même mis des couleurs pour que ça soit plus fun)"
                colorone="black"
                colortwo="#e68a00"
                size="0.8rem"
              />
              <Box sx={{ height: "20px" }} />
              <AnimatedTextGradient
                text="À propos de MissMyEx"
                colorone="black"
                colortwo="#e68a00"
                size="1rem"
                weight={900}
              />
              <AnimatedTextGradient
                text="Ce site vient de l'expérience perso du créateur. On veut juste aider les gens à se retrouver, pas faire un max de bénéfices."
                colorone="black"
                colortwo="#754600"
                size="0.8rem"
              />
              <Box sx={{ height: "10px" }} />
              <AnimatedTextGradient
                text="Utilisation du service"
                colorone="black"
                colortwo="#e68a00"
                size="1rem"
                weight={900}
              />
              <AnimatedTextGradient
                text="Pour profiter de MissMyEx, tu dois accepter ces conditions. C'est un peu comme dire oui pour entrer dans une super communauté."
                colorone="black"
                colortwo="#754600"
                size="0.8rem"
              />
              <Box sx={{ height: "10px" }} />
              <AnimatedTextGradient
                text="Les SMS, c'est pas magique"
                colorone="black"
                colortwo="#e68a00"
                size="1rem"
                weight={900}
              />
              <AnimatedTextGradient
                text="Déjà que c'est payant pour nous, les messages peuvent ne pas arriver. C'est rare mais ça dépend du réseau, et on n'y peut pas grand-chose, mais on préfère prévenir."
                colorone="black"
                colortwo="#754600"
                size="0.8rem"
              />
              <Box sx={{ height: "10px" }} />
              <AnimatedTextGradient
                text="Tes données, c'est sacré"
                colorone="black"
                colortwo="#e68a00"
                size="1rem"
                weight={900}
              />
              <AnimatedTextGradient
                text="Si jamais tu veux changer des infos ou les supprimer, un petit mail à rgpd@missmyex.fr et on s'occupe de tout, conformément aux règles RGPD."
                colorone="black"
                colortwo="#754600"
                size="0.8rem"
              />
              <Box sx={{ height: "10px" }} />
              <AnimatedTextGradient
                text="On est là pour toi"
                colorone="black"
                colortwo="#e68a00"
                size="1rem"
                weight={900}
              />
              <AnimatedTextGradient
                text="Si t'as un souci ou une question, n'hésite pas à nous contacter par mail à contact@missmyex.fr, on veut que ton expérience sur MissMyEx soit top."
                colorone="black"
                colortwo="#754600"
                size="0.8rem"
              />
            </Box>

            <Box sx={{ height: "50px" }} />

            {/* Contenu des CGU */}
            {/* <Typography
              sx={{ color: 'black', fontSize: '0.8rem', textAlign: 'justify', fontWeight: 'normal' }}
            /> */}
            {/* Texte des CGU */}
            <Typography variant="h5" gutterBottom component="div">
              Préambule
            </Typography>
            <Typography variant="body2" paragraph>
              Ce document établit les conditions juridiques et contractuelles
              régissant l'utilisation du site MissMyEx, créé dans le but de
              faciliter la communication et la reconnexion entre individus.
              MissMyEx, en offrant ce service, ne vise pas un profit commercial
              excessif, mais plutôt une contribution positive à la société en
              permettant aux individus de renouer des liens.
            </Typography>

            <Typography variant="h5" gutterBottom component="div">
              Article 1 : Objet et Acceptation
            </Typography>
            <Typography variant="body2" paragraph>
              Les présentes Conditions Générales d'Utilisation (CGU) définissent
              les termes et conditions selon lesquels les services de MissMyEx
              sont fournis aux utilisateurs. En accédant au site et en utilisant
              ses services, l'utilisateur reconnaît avoir lu, compris et accepté
              sans réserve l'intégralité des présentes CGU.
            </Typography>

            <Typography variant="body2" paragraph>
              1.1 Portée et Enjeux
            </Typography>
            <Typography variant="body2" paragraph>
              Nature des Services : MissMyEx offre un service permettant aux
              utilisateurs de savoir si une personne spécifique, comme un
              ex-partenaire, est également intéressée par un renouveau de
              communication. Le service repose sur la fourniture volontaire de
              numéros de téléphone et leur traitement conformément aux
              dispositions relatives à la protection des données personnelles.
            </Typography>
            <Typography variant="body2" paragraph>
              1.2 Consentement Éclairé
            </Typography>
            <Typography variant="body2" paragraph>
              Acceptation des Risques et Responsabilités : L'utilisateur, en
              s'inscrivant sur le site, accepte de manière éclairée les risques
              inhérents à la divulgation de ses données personnelles et celles
              d'autrui dans le cadre de l'utilisation du service proposé par
              MissMyEx.
            </Typography>

            <Typography variant="h5" gutterBottom component="div">
              Article 2 : Description des Services
            </Typography>
            <Typography variant="body2" paragraph>
              2.1 Fonctionnement et Modalités d'Utilisation
            </Typography>
            <Typography variant="body2" paragraph>
              Inscription et Vérification : L'inscription sur MissMyEx requiert
              la fourniture d'un numéro de téléphone et la réception d'un code
              de vérification par SMS. Ce processus est essentiel pour assurer
              l'authenticité des utilisateurs et la fiabilité du service.
            </Typography>
            <Typography variant="body2" paragraph>
              Entrée et Traitement des Numéros : Suite à la vérification,
              l'utilisateur a la possibilité d'entrer le numéro de téléphone
              d'une personne spécifique. MissMyEx traite ces informations pour
              déterminer si la personne concernée a manifesté un intérêt
              similaire pour le service.
            </Typography>

            <Typography variant="body2" paragraph>
              2.2 Services Optionnels et Payants
            </Typography>
            <Typography variant="body2" paragraph>
              Service d'Alerte Payant : En cas de non-inscription de la personne
              recherchée, l'utilisateur peut choisir d'être notifié par SMS si
              cette personne s'inscrit ultérieurement. Ce service est proposé à
              un tarif de 1,29 EUR, et sa mise en œuvre est conforme aux
              standards de sécurité et de confidentialité.
            </Typography>

            <Typography variant="body2" paragraph>
              2.3 Modifications et Restrictions
            </Typography>
            <Typography variant="body2" paragraph>
              Changement de Numéro de Contact : L'utilisateur peut modifier le
              numéro de téléphone de la personne recherchée en s'acquittant d'un
              montant de 1,29 EUR. Cette fonctionnalité est soumise à certaines
              restrictions et peut ne pas être disponible en permanence.
            </Typography>

            <Typography variant="h5" gutterBottom component="div">
              Article 3 : Gestion des Paiements
            </Typography>
            <Typography variant="body2" paragraph>
              3.1 Sécurité des Transactions
            </Typography>
            <Typography variant="body2" paragraph>
              Traitement des Transactions : Les transactions financières sur
              MissMyEx sont traitées de manière sécurisée par Stripe,
              conformément aux normes de sécurité des données de l'industrie des
              cartes de paiement (PCI DSS). MissMyEx ne stocke ni ne gère
              directement les données de paiement des utilisateurs.
            </Typography>

            <Typography variant="body2" paragraph>
              3.2 Transparence et Responsabilité Financière
            </Typography>
            <Typography variant="body2" paragraph>
              Aucuns Frais Cachés : MissMyEx s'engage à la transparence totale
              concernant les coûts des services proposés. Tous les frais
              supplémentaires, tels que les frais pour le changement de numéro
              de contact, sont clairement indiqués avant toute transaction.
            </Typography>

            <Typography variant="h5" gutterBottom component="div">
              Article 4 : Protection des Données Personnelles
            </Typography>
            <Typography variant="body2" paragraph>
              4.1 Conformité au RGPD
            </Typography>
            <Typography variant="body2" paragraph>
              Engagement en matière de Protection des Données : MissMyEx
              respecte strictement les dispositions du Règlement Général sur la
              Protection des Données (RGPD) en vigueur dans l'Union Européenne.
              L'ensemble des données personnelles est traité de manière à
              garantir un niveau de protection élevé.
            </Typography>
            <Typography variant="body2" paragraph>
              4.2 Droit à l'Effacement et à la Modification
            </Typography>
            <Typography variant="body2" paragraph>
              Exercice des Droits des Utilisateurs : Conformément au RGPD, les
              utilisateurs de MissMyEx peuvent demander l'accès à leurs données
              personnelles, leur rectification ou leur effacement. Ces demandes
              doivent être adressées à rgpd@missmyex.fr.
            </Typography>
            <Typography variant="body2" paragraph>
              4.3 Sécurité et Confidentialité
            </Typography>
            <Typography variant="body2" paragraph>
              Mesures de Sécurité : Des mesures techniques et organisationnelles
              appropriées sont mises en œuvre pour protéger les données
              personnelles contre la perte, l'usage abusif, l'accès non
              autorisé, la divulgation, l'altération ou la destruction.
            </Typography>

            <Typography variant="h5" gutterBottom component="div">
              Article 5 : Limitations de Responsabilité
            </Typography>
            <Typography variant="body2" paragraph>
              5.1 Responsabilité en Cas de Défaillance Technique
            </Typography>
            <Typography variant="body2" paragraph>
              Exclusions de Responsabilité : MissMyEx ne peut être tenu
              responsable des interruptions de service ou des défauts de
              transmission de SMS liés à des facteurs externes tels que des
              problèmes de réseau, des pannes de serveur ou d'autres
              défaillances techniques indépendantes de sa volonté.
            </Typography>
            <Typography variant="body2" paragraph>
              5.2 Garanties et Exclusions
            </Typography>
            <Typography variant="body2" paragraph>
              Absence de Garanties : MissMyEx ne garantit pas que les services
              répondront parfaitement aux attentes de l'utilisateur ou que les
              résultats obtenus seront exacts ou fiables. Toute utilisation des
              services se fait aux risques et périls de l'utilisateur.
            </Typography>

            <Typography variant="h5" gutterBottom component="div">
              Article 6 : Modifications des Conditions
            </Typography>
            <Typography variant="body2" paragraph>
              6.1 Mise à Jour et Communication
            </Typography>
            <Typography variant="body2" paragraph>
              Droit de Modification : MissMyEx se réserve le droit de modifier
              ces CGU à tout moment. Les utilisateurs seront informés de toute
              modification substantielle par une communication appropriée sur le
              site ou par d'autres moyens de communication.
            </Typography>
            <Typography variant="body2" paragraph>
              6.2 Acceptation des Modifications
            </Typography>
            <Typography variant="body2" paragraph>
              Consentement Continu : L'utilisation continue du site après toute
              modification des CGU constitue une acceptation tacite de ces
              modifications par l'utilisateur.
            </Typography>

            <Typography variant="h5" gutterBottom component="div">
              Article 7 : Droit Applicable et Juridiction
            </Typography>
            <Typography variant="body2" paragraph>
              7.1 Application du Droit Français
            </Typography>
            <Typography variant="body2" paragraph>
              Juridiction et Législation : Ces CGU sont régies par le droit
              français. Tout litige ou réclamation découlant de ou en relation
              avec ces CGU sera soumis à la compétence exclusive des tribunaux
              français.
            </Typography>
            <Typography variant="body2" paragraph>
              7.2 Résolution Amiable des Litiges
            </Typography>
            <Typography variant="body2" paragraph>
              Privilégier la Médiation : Avant toute action judiciaire, les
              parties s'engagent à tenter de résoudre tout différend de manière
              amiable.
            </Typography>

            {/* </Typography> */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CGVPopup;
