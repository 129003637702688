import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { Controller, useForm } from 'react-hook-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import styled from "@emotion/styled";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const StyledMuiTelInput = styled(MuiTelInput)`
    & .MuiTelInput-TextField {
      border: 1px solid white;
      
      &:hover {
        border-color: white;
      }

      &.Mui-focused {
        border-color: white;
      }
    }

    & .MuiOutlinedInput-root {
      & fieldset {
        border-color: white;
      }
      &:hover fieldset {
        border-color: white;
      }
      &.Mui-focused fieldset {
        border-color: white;
      }
    }

    .MuiInputLabel-root {
      color: white;

      &.Mui-focused {
        color: white; // quand le champ est en focus
      }

      &.Mui-error {
        color: red; // vous pouvez conserver le rouge ou choisir une autre couleur
      }
    }

    .MuiInputBase-input {
      &::placeholder {
        color: white;
        font-family: "PetitCochon", sans-serif;
        opacity: 1;
      }
    }

    & .MuiTelInput-Flag,
    & .MuiTelInput-IconButton {
      border-color: white;
    }

    & input {
      font-family: "PetitCochon", sans-serif;
      font-size: 1.5rem;
      color: white;
    }
`;
const SimplePhoneNumberInput = ({ onChange, label, onNumberValid, canEdit, number = "" }) => {
  const { t } = useTranslation();
  
  const { control, setValue } = useForm({
    defaultValues: {
      tel: number,
    },
  });

  useEffect(() => {
    setValue("tel", number);
  }, [number, setValue]);

    const handleNumberChange = (value) => {
      if (!canEdit) {
          return;
      }
      if (value.startsWith("+0")) {
        value = "+33" + value.slice(2);
      }
      
      setValue("tel", value);
  
      const phoneNumber = parsePhoneNumberFromString(value, "FR");
      if (phoneNumber && phoneNumber.isValid()) {
          onNumberValid(phoneNumber.formatInternational());
      }
      else {
          onNumberValid("");
      }
    };
  
    return (
      <div style={{ padding: '20px' }}>
        <Controller
          name="tel"
          control={control}
          render={({ field }) => (
            <StyledMuiTelInput
              {...field}
              label={t(label)}
              defaultCountry="FR"
              onChange={(value) => {
                handleNumberChange(value);
                if (canEdit)  
                  field.onChange(value);
              }}
              // Désactiver l'input si canEdit est false
              disabled={!canEdit}
            />
          )}
        />
      </div>
    );
  };
  

export default SimplePhoneNumberInput;
