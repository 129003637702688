import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AnimatedTextGradient from "../RevealDashboard/AnimatedTextGradient";
import PaymentButtons from "./PaymentButton";


const PaymentDialog = ({ open, onClose }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{ textAlign: "center" }}
      PaperProps={{
        style: {
            maxHeight: "90vh",
            overflowY: "auto",
          backgroundColor: "rgb(244, 236, 236)", // Fond partiellement transparent
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle>
        <AnimatedTextGradient
          text="Activer l'alerte SMS"
          colorone="black"
          colortwo="black"
          size="1.5rem"
        />
      </DialogTitle>
      <Box sx={{ 
        flexDirection: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        }}>
      <Box
        sx={{
          border: "0px solid #81910d",
          margin: "auto",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#e6e6d6",
        }}
      >
        <AnimatedTextGradient
          text="1.29€"
          colorone="#d88f22"
          colortwo="#3a2608"
          size="1.5rem"
          // epaisseur
          fontWeight="bold"
        />
      <AnimatedTextGradient
        text="mensuel"
        colorone="black"
        colortwo="grey"
        size="0.7rem"
      />
      </Box>

        </Box>
      <Box sx={{ height: "10px" }}></Box>
      <AnimatedTextGradient
        text="Annulation en un clic"
        colorone="black"
        colortwo="green"
        size="0.65rem"
      />
    <AnimatedTextGradient
        text="et automatiquement au match"
        colorone="black"
        colortwo="green"
        size="0.65rem"
      />
      <Box sx={{ height: "15px" }}></Box>
      <Box
        sx={{
          border: "1px solid black",
          marginRight: "80px",
          marginLeft: "80px",
          borderRadius: "10px",
          backgroundColor: "#e6e6d6",
        }}
      />
      <Box sx={{ height: "15px" }}></Box>
      <AnimatedTextGradient
        text="Pourquoi payant?"
        colorone="black"
        colortwo="#d88f22"
        size="1.3rem"
      />
      <Box sx={{ height: "15px" }}></Box>
      {/* <AnimatedTextGradient
        text="This is how your donation is used"
        colorone="black"
        colortwo="black"
        size="0.7rem"
      /> */}
      {/* <Box sx={{ height: "10px" }}></Box> */}
      <Box
        sx={{
          margin: "auto",
          padding: "5px",
          borderRadius: "10px",
          // put at left
          backgroundColor: "#e6e6d6",
        }}
      >
        <AnimatedTextGradient
          text="Soyons transparent:"
          colorone="black"
          colortwo="black"
          size="0.8rem"
        />
        <Box sx={{ height: "8px" }}></Box>
        <AnimatedTextGradient
          text="1 SMS coûte 0.08€"
          colorone="black"
          colortwo="black"
          size="0.7rem"
        />{" "}
        <AnimatedTextGradient
          text="Frais de paiement 0.29€"
          colorone="black"
          colortwo="black"
          size="0.7rem"
        />
        <AnimatedTextGradient
          text="Maintenance & Hébergement 0.50€"
          colorone="black"
          colortwo="black"
          size="0.7rem"
        />
        <AnimatedTextGradient
          text="Taxes 0.33€"
          colorone="black"
          colortwo="black"
          size="0.7rem"
        />
        <AnimatedTextGradient
          text="Marge 0.09€"
          colorone="black"
          colortwo="black"
          size="0.7rem"
        />
      </Box>

            <Box sx={{ height: "25px" }}></Box>
      <Box
        sx={{
          border: "1px solid black",
          marginRight: "80px",
          marginLeft: "80px",
          borderRadius: "10px",
          backgroundColor: "#e6e6d6",
        }}
      />
      <Box sx={{ height: "15px" }}></Box>
      <Box sx={{ height: "10px" }}></Box>
      <AnimatedTextGradient
        text="Aucun frais cachés"
        colorone="black"
        colortwo="#d88f22"
        size="0.8rem"
      />
      <AnimatedTextGradient
        text="Nous ne vendons aucune données"
        colorone="black"
        colortwo="#d88f22"
        size="0.8rem"
      />
      <AnimatedTextGradient
        text="Nous n'utilisons pas de pub"
        colorone="black"
        colortwo="#d88f22"
        size="0.8rem"
      />
      <AnimatedTextGradient
        text="Nous protégeons votre vie privée"
        colorone="black"
        colortwo="#d88f22"
        size="0.8rem"
      />
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          {/* Ici, insérez votre composant de graphique */}
        </Box>
        {/* <Box sx={{ textAlign: "right" }}>
          <Typography variant="body1">Total: $0.99</Typography>
          <Typography variant="body2">Taxes: $0.33</Typography>
          <Typography variant="body2">SMS Cost: $0.08</Typography>
          <Typography variant="body2">Hosting & Maintenance: $0.50</Typography>
          <Typography variant="body2">Creator's Share: $0.09</Typography>
        </Box> */}
        {/* Zone pour les boutons de paiement */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* Remplacez ceci par vos boutons de paiement */}
          <Elements stripe={stripePromise}>

          <PaymentButtons />
          </Elements>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
