import React from 'react';
import { Box, Typography } from '@mui/material';
import ContributorsPopup from './ContributorsPopup'; // Assuming ContributorsPopup is in the same directory
import CGVPopup from './CGVPopup';

const Footer = ({ setShowModal, showModal }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '98%',
        padding: '0 1rem',
      }}
    >
      <ContributorsPopup />
      <Typography
        onClick={() => setShowModal && setShowModal(true)}
        sx={{
          color: '#ffbd59',
          fontSize: '0.8rem',
          fontWeight: 'bold',
          cursor: 'pointer',
          visibility: showModal ? 'hidden' : 'visible',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        Manuel
      </Typography>
      <Typography
        sx={{
          color: '#ffbd59',
          fontSize: '0.8rem',
          textAlign: 'right',
          fontWeight: 'bold',
        }}
      >
        <CGVPopup />
      </Typography>
    </Box>
  );
};

export default Footer;
