import React, { useState } from "react";
import { Box } from "@mui/material";
import AnimatedTextGradient from "./AnimatedTextGradient";
import downArrow from "../assets/arrowDown.gif";
import upArrow from "../assets/UpArrow.gif";
import DownArrowGreen from "../assets/DownArrowGreen.gif";
import UpArrowGreen from "../assets/UpArrowGreen.gif";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import PaymentDialog from "../Payment/PaiementDialog";
import { useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import { loadStripe } from "@stripe/stripe-js";

// const bounceAnimation = `
//   @keyframes bounce {
//     0% {
//       transform: translateY(0);
//     }
//     50% {
//       transform: translateY(-10px);
//     }
//     100% {
//       transform: translateY(0);
//     }
//   }
// `;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AnimatedBox = ({ active }) => {
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const redirectToCheckout = async () => {
    setLoading(true);
    try {
      // Appeler votre API pour créer une session de paiement
      const response = await axiosInstance.post(
        "/api/v1/create-checkout-session",
        {
          amount: 129, // Montant en centimes
          userNumber: localStorage.getItem("phoneNumber"), // Numéro de téléphone de l'utilisateur
        }
      );

      const stripe = await stripePromise;
      const { sessionId } = response.data;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Erreur lors de la redirection vers Checkout:", error);
        setLoading(false);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session de Checkout:",
        error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        setShowContent(true);
      }, 270);
    } else {
      setShowContent(false);
    }
  }, [expanded]);

  const handleTurnAlert = async () => {
    // make an AB test here
    // if user id is even, show the dialog
    // if user id is odd, redirect to checkout
    const userId = parseInt(localStorage.getItem("userId"));
    if (userId % 2 === 0) {
      handleOpen();
    } else {
      redirectToCheckout();
    }
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px 15px", // Réduit le padding pour diminuer l'espace au-dessus du texte
        borderRadius: "20px",
        border: active ? "1px solid #154700" : "1px solid #47000080",
        boxShadow: active
          ? "1px 1px 0px 1px #154700"
          : "1px 1px 0px 1px #470000",
        // boxShadow: "1px 1px 0px 1px #470000",
        transition: "all 0.5s ease",
        height: expanded ? (!active ? "300px" : "240px") : "120px", // Hauteur ajustée pour le mouvement de la flèche
        position: "relative",
        width: "298px", // Largeur fixe
      }}
    >
      {/* <style>{bounceAnimation}</style> */}
      <Box sx={{ position: "absolute", top: "20px", textAlign: "center" }}>
        <AnimatedTextGradient
          text="ALERTE SMS"
          colortwo="white"
          colorone="#f0d9b7"
          fontFamily="PetitCochon"
          letterSpacing="0.4rem"
          weight={900}
          size="1.3rem"
        />
        {!active ? (
          <AnimatedTextGradient
            text="DÉSACTIVÉ"
            colorone="rgba(237,134,70,1)"
            colortwo="#d93636"
            fontFamily="PetitCochon"
            letterSpacing="0.1rem"
            weight={900}
            size="1.1rem"
          />
        ) : (
          <AnimatedTextGradient
            text="ACTIVÉ"
            colortwo="#154700"
            colorone="#2e9900"
            fontFamily="PetitCochon"
            letterSpacing="0.1rem"
            weight={900}
            size="1.1rem"
          />
        )}
      </Box>
      <img
        src={
          expanded
            ? !active
              ? upArrow
              : UpArrowGreen
            : !active
            ? downArrow
            : DownArrowGreen
        }
        alt={expanded ? "Up Arrow" : "Down Arrow"}
        onClick={toggleExpand}
        style={{
          cursor: "pointer",
          marginBottom: "-3px",
          //   animation: "bounce 1s infinite",
          position: "absolute",
          bottom: "10px",
          opacity: expanded ? 1 : 1,
          width: "50px",
        }}
      />
      {showContent && (
        <>
          {active && <Box sx={{ height: "25px" }}></Box>}
          <AnimatedTextGradient
            text={
              !active ? "Ne rate jamais ce match" : "Merci pour ton support !"
            }
            colorone="white"
            colortwo="#fff5d6"
            // letterSpacing="0.4rem"
            weight={900}
            size="1rem"
          />
          <PaymentDialog open={openDialog} onClose={handleClose} />

          <Box sx={{ height: "15px" }}></Box>
          <AnimatedTextGradient
            text={
              !active
                ? "Comme ça tu seras immédiatement au"
                : "Tu seras immédiatement au"
            }
            colorone="white"
            colortwo="#fff5d6"
            // letterSpacing="0.4rem"
            weight={900}
            size="0.8rem"
          />
          <AnimatedTextGradient
            text="courant si ton contact rentre ton numéro."
            colorone="white"
            colortwo="#fff5d6"
            // letterSpacing="0.4rem"
            weight={900}
            size="0.8rem"
          />
          <Box sx={{ height: "25px" }}></Box>
          {!active && (
            <Button
              onClick={handleTurnAlert}
              variant="contained"
              disabled={loading}
              style={{
                width: "70%",
                backgroundColor: "rgb(245, 192, 106)",
                boxShadow: "2px 2px 0px 2px rgba(237,134,70,1)",
                // color: loading ? 'black' : '#000'
              }}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: "#f5f5f5",
                  fontWeight: "bold",
                  marginTop: 0.6,
                  letterSpacing: "0.15rem",
                  fontFamily: "PetitCochon",
                  fontSize: "1.0rem",
                }}
              >
                {loading ? "Chargement..." : "ACTIVER mon alerte"}
              </Typography>
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default AnimatedBox;
