import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AnimatedTextGradient from "../RevealDashboard/AnimatedTextGradient";
import FoxBecarful from "../assets/warningicon.gif";

export default function ConfirmationDialog({
  number,
  open,
  handleClose,
  Confirm,
}) {
  const [countdown, setCountdown] = useState(3);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setCountdown(3);
    }
  }, [open]);

  useEffect(() => {
    let timer;
    if (loading && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setLoading(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [loading, countdown]);

  const handleConfirm = () => {
    Confirm && Confirm(); // Calling Confirm function when "Confirm" button is clicked.
  };

  // receive a french number like +33612345678 and return 06 12 34 56 78 with spaces
  const formatNumber = (number) => {
    let formattedNumber = number;
    if (number.startsWith("+33")) {
      formattedNumber = "0" + number.slice(3);
    }
    return formattedNumber.replace(/(.{2})/g, "$1 ").trim();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center", paddingBottom: 0 }}>
        <img src={FoxBecarful} alt="FoxBecarful" style={{ width: "25%" }} />
        {/* <Typography
          variant="h4"
          component="div"
          style={{ color: "#ffbd59" }}
          sx={{ marginBottom: 1 }}
        >
          ⚠️
        </Typography> */}

      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <AnimatedTextGradient
          text={formatNumber(number)}
          colorone="#f6a650"
          colortwo="#ffbd59"
          weight={900}
          size="2.3rem"
        />
        {/* </Typography> */}
        <Box sx={{ height: 10 }} />

        <AnimatedTextGradient
          text="Êtes-vous certains de verrouiller ce numéro ? "
          colorone="black"
          colortwo="black"
          size="1rem"
        />
        <DialogContentText>
          {/* Êtes-vous certains de verrouiller ce numéro ?  */}
          <AnimatedTextGradient
            text="Vous ne pourrez plus le changer."
            colorone="#cd2d2d"
            colortwo="#cd2d2d"
            weight={900}
            size="1.2rem"
          />
          <Box sx={{ height: 30 }} />
          <AnimatedTextGradient
            text="Après avoir cliqué sur Confirmer, vous saurez si votre contact a fait de même."
            colorone="black"
            colortwo="black"
            weight={800}
            size="0.9rem"
          />
          <Box sx={{ height: 5 }} />
          <AnimatedTextGradient
            text="Vous n'avez le droit qu'à un seul numéro."
            colorone="black"
            colortwo="grey"
            weight={800}
            size="0.8rem"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
        <Button
          onClick={handleClose}
          //   variant="outlined"
          variant="outlined"
          //   disabled={loading}
          style={{
            backgroundColor: "#ffffff",
            borderColor: "#ffbd59",
            // boxShadow: "-2px 2px 0px 2px rgba(237,134,70,1)",
          }}
        >
          <Typography
            variant="body1"
            component="div"
            style={{ color: "#212121", fontWeight: "bold", fontSize: "1rem" }}
          >
            Annuler
          </Typography>
        </Button>
        <Box position="relative" ml={2}>
          <Button
            onClick={handleConfirm}
            variant="contained"
            disabled={loading}
            style={{
              backgroundColor: loading ? "#ccc" : "rgb(245, 192, 106)",
              color: loading ? "#999" : "#000",
              boxShadow: loading
                ? "2px 2px 0px 2px rgb(111, 109, 109)"
                : "2px 2px 0px 2px rgba(237,134,70,1)",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              style={{ color: "#212121", fontWeight: "bold", fontSize: "1rem" }}
            >
              {/* Pay by card */}
              {loading ? countdown : "Confirmer"}
            </Typography>
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              style={{
                color: "black",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
